import { ActionEvent, PackagePreviewModel, TaskModel } from '../../tasks/types';
import { ActionConstraint, ActionDefinition } from '../../administration/types/Actions';
import { Utils } from './Utils';

export default class ActionHelper {
    static canExecute(action: ActionDefinition, task: TaskModel, pkg?: PackagePreviewModel, events?: ActionEvent[]) {
        return (
            // Meets contraints:
            (action.constraints ? action.constraints.every(c => this.checkConstraint(c, task, pkg)) : true)
            &&
            // No running actions of the same type:
            !this.hasActionInProgress(action, task, pkg, events)
        );
    }

    static hasActionInProgress(action: ActionDefinition, task: TaskModel, pkg?: PackagePreviewModel, events?: ActionEvent[]) {
        return events ?
            events.some(e =>
                e.taskId === task.id &&
                (pkg === undefined || e.packageId === pkg.id) &&
                e.actionType === action.actionType &&
                e.status === 'Started')
            :
            false;
    }
    
    static checkConstraint(constraint: ActionConstraint, task: TaskModel, pkg?: PackagePreviewModel) {
        let result: boolean = true;

        let sourceValue;

        switch (constraint.fieldType) {
        case 'TaskProperty':
            sourceValue = task && Object.keys(task).includes(Utils.unCapitalizeWord(constraint.name)) ? task[Utils.unCapitalizeWord(constraint.name)] : undefined;
            break;
        case 'AttachmentProperty':
            sourceValue = pkg && Object.keys(pkg).includes(Utils.unCapitalizeWord(constraint.name)) ? pkg[Utils.unCapitalizeWord(constraint.name)] : undefined;
            break;
        case 'Metadata':
            sourceValue = task && task.metadata && Object.keys(task.metadata).includes(constraint.name) ? task.metadata[constraint.name] : undefined;
            break;
        default:
            sourceValue = undefined;
            break;
        }

        switch(constraint.operator) {
        case 'Equal':
            result = sourceValue == constraint.value;
            break;
        case 'NotEqual':
            result = sourceValue != constraint.value;
            break;
        case 'IsNull':
            result = sourceValue === undefined || sourceValue === null;
            break;
        case 'NotNull':
            result = sourceValue !== undefined && sourceValue !== null;
            break;
        case 'AnyOf':
            result = sourceValue == constraint.value;
            break;
        case 'NoneOf':
            result = sourceValue == constraint.value;
            break;
        case 'GreaterThan':
            result = constraint.value !== undefined && (sourceValue > constraint.value);
            break;
        case 'LessThan':
            result = constraint.value !== undefined && (sourceValue < constraint.value);
            break;
        default:
            result = true;
            break;
        }

        // console.log('Constraint check [', constraint.fieldType, ', ', constraint.name, ', ',
        //     constraint.operator, ', ', constraint.value, '] => [', sourceValue, ', ', result, ']');

        return constraint.kind === 'Requirement' ? result : !result;
    }
}