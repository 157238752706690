import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Input, Row, Col, Tooltip, Form, Radio, message } from 'antd';
import  { useForm } from 'antd/lib/form/Form';
import permissions, { AppPermissions } from '../../authorization/Permissions';
import { RoleModel } from '../types/UserModel';
import UsersManagerStore, { LoginTypes } from '../stores/UsersManagerStore';
import { CustomRole } from '../types/CustomRoleModel';
import { EMAIL_REGEX_PATTERN, Utils } from '../../common/misc/Utils';
import { ProjectsAssignmentTable } from './ProjectsAssignmentTable';

const FormItem = Form.Item;
type Props = {
    store: UsersManagerStore
};

export const AddUserDialog: React.FC<Props> = observer(({ store }) => {
    const additionalRoles = [] as string[];
    const [form] = useForm();
    const [currentStep, setCurrentStep] = React.useState(0);
    const [currentRole, setCurrentRole] = React.useState('');

    const handleCancel = () => {
        store!.setIsAddUserDialogVisible(false);
        form.resetFields();
        setCurrentStep(0);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const compareToFirstPassword = (rule: any, value: any, callback: any) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Passwords should match!');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const similarEmailsValidator = (rule: any, value: any, callback: any) => { 
        let emailsToValidate = store!.users.filter(u => u.email).map(u => u.email.toLowerCase());
        if (value && emailsToValidate.includes(value.toLowerCase())) {
            callback('User with the same email is already registered!');
        } else {
            callback();
        }
    };

    const getPermissionsString = (role: string) => {
        if (permissions[role]) {
            const rolePermissions =  permissions[role] as AppPermissions[];
            return rolePermissions.map(x=> <>{x}<br/></>);
        }
        return null;
    };

    const getBasicRoles = () => {
        if (!store?.sortedRoles && !store?.customRoles) {
            return [];
        }

        const allRoles: (RoleModel | CustomRole)[] = [...store.sortedRoles, ...store.customRoles];
        return allRoles.filter(r => !additionalRoles.includes(r.name.toLowerCase()));
    };

    const isCustomRole = (r: unknown) => {
        const properties = Object.getOwnPropertyNames(r);
        return properties.includes('permissions');
    };

    const getRolePermissions = (r: RoleModel | CustomRole) => {
        return isCustomRole(r) ? (r as CustomRole).permissions.map(p => (<>{p}<br /></>)) : getPermissionsString(r.name);
    };

    const handleNextButtonClick = async() => {
        if (currentStep === 1) {
            if (store.editableProjectsForUser.length === 0) {
                message.error('Please select at least one project');
                return;
            }
            await store!.createUpdateUser().then(() => {
                store.clearEditableProjects();
                setCurrentStep(0);
                form.resetFields();
            });
        } else {
            form.validateFields().then(async(values) => {
                if (form.getFieldValue('role').includes('admin')) {
                    await store!.createUpdateUser(values);
                    form.resetFields();
                    return;
                }
                store.setNewUserFormValues(values);
                setCurrentStep(1);
            }).catch((err) => {
                console.log(err);
            });
            
        }
    };

    const handleBackButtonClick = () => {
        if (currentStep === 0) {
            handleCancel();
        } else {
            setCurrentStep(0);
        }
    };

    const getFooter = () => {
        const footer = [
            <Button className="light" data-id="add-user-dialog-cancel" size="large" key="back" onClick={handleBackButtonClick} disabled={store.tableIsLoading}>
                {currentStep === 0 ? 'Cancel' :  'Back'}
            </Button>,
            <Button data-id="add-user-dialog-submit" size="large" key="submit" type="primary" onClick={handleNextButtonClick} loading={store.tableIsLoading}>
                {(currentStep === 0 && currentRole === 'user') ? 'Next' :  'Add user'}
            </Button>
        ];
        const cancelButton = 
            <Button className="light" data-id="add-user-dialog-cancel" size="large" key="back" onClick={handleCancel} disabled={store.tableIsLoading}>
                Cancel
            </Button>;
        return currentStep === 0 ? footer : [cancelButton, ...footer];
    };


    return (
        <Modal
            open={store!.isAddUserDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            title={currentStep === 0 ? 'New user' : 'Project assignments'}
            destroyOnClose
            width={660}
            centered
            className="alpha-modal"
            footer={getFooter()}
        >
            {currentStep === 0 ? <Form 
                layout="vertical"
                data-id="add-user-dialog-form"
                form={form} 
                className="alpha-form"
                initialValues={{loginType: LoginTypes.internalUser}}>
                <Row gutter={20}>
                    <Col span="12">
                        <FormItem
                            label={<span className="dialog-field-label">First name</span>}
                            className="dialog-field"
                            name="firstName"
                            rules={[
                                { required: true, message: 'First name is requried', whitespace: true }
                            ]}
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem
                            label={<span className="dialog-field-label">Last name</span>}
                            name="lastName"
                            rules={[
                                { required: true, message: 'Last name is required', whitespace: true }
                            ]}
                        >
                            <Input/>
                        </FormItem>
                    </Col>

                    <Col span="24">
                        <FormItem 
                            label={<span className="dialog-field-label">Username</span>}
                            name="userName"
                            rules = {[
                                { required: true, message: 'Username is required', whitespace: true }
                            ]}
                        ><Input/>
                        </FormItem>
                    </Col>
                    <Col span="24">
                        <FormItem
                            label={<span className="dialog-field-label">Email</span>}
                            name="email"
                            rules={[
                                { required: true, message: 'Email is required', whitespace: true },
                                {   
                                    pattern: EMAIL_REGEX_PATTERN, 
                                    message: 'Input valid email address please.'
                                },
                                {validator: similarEmailsValidator}
                            ]}
                        >
                            <Input/>
                        </FormItem>
                    </Col>
                    <Col span="12">
                        <FormItem
                            label={<span className="dialog-field-label">Password</span>}
                            name="password"
                            rules={[
                                { required: true, message: 'Password is required', whitespace: true },
                                { 
                                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm, 
                                    message: 'Password should beat least 6 characters long and contain at least one number, upper case and lower case character.'
                                }]
                            }
                        >
                            <Input.Password 
                                iconRender={visible => (visible 
                                    ? <span style={{height: 22}}><i className="alpha-icon md eye-visible" /></span> 
                                    : <span style={{height: 22}}><i className="alpha-icon md eye-invisible" /></span>)
                                }
                            />
                        </FormItem>
                    </Col>
                    
                    <Col span="12">
                        <FormItem
                            label={<span className="dialog-field-label">Confirm password</span>}
                            name="confirmPassword"
                            rules={[
                                { required: true, message: 'Password is required', whitespace: true },
                                { validator: compareToFirstPassword}
                            ]
                            }
                        >
                            <Input.Password 
                                iconRender={visible => (visible 
                                    ? <span style={{height: 22}}><i className="alpha-icon md eye-visible" /></span> 
                                    : <span style={{height: 22}}><i className="alpha-icon md eye-invisible" /></span>)
                                }
                            />
                        </FormItem> 
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <div className="dialog-administation-role-form-item">
                            <FormItem
                                label={<span className="dialog-field-label">Basic roles</span>}
                                name="role"
                                rules={[{
                                    required: true, message: 'Role is required'
                                }]}
                            >
                                <Radio.Group style={{width: '100%'}}>
                                    {getBasicRoles().map(r => (
                                        <Radio 
                                            className="checkbox-with-subtitle" 
                                            key={r.id} 
                                            value={isCustomRole(r) ? r.id : r.name}
                                            onChange={() => setCurrentRole(r.name)}
                                        >
                                            <div>
                                                <div style={{display: 'inline'}}>
                                                    {Utils.getAppRoleDisplayName(r.name)}
                                                </div>
                                                <div style={{display: 'inline-block'}}> 
                                                    <Tooltip title={getRolePermissions(r)}>
                                                        <i className="alpha-icon lg question-icon" style={{verticalAlign: 'middle'}} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </FormItem>
                        </div>
                    </Col>
                </Row>
            </Form> : <ProjectsAssignmentTable store={store}/>}
        </Modal>
    );
});

export default AddUserDialog;