import * as React from 'react';
import { TaskCreateVisualStore } from '../stores';
import { Observer, observer } from 'mobx-react-lite';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button, Input, Popconfirm, Dropdown, Switch, Tooltip, Select, MenuProps } from 'antd';
import DraggableItemWrapper from '../../common/components/DraggableItemWrapper';
import { OnHitEventArgs } from 'react-drag-drop-container';
import { ALL_PROJECTS } from '../screens/TasksPage';
import TasksGridVisualStore from '../stores/TasksGridVisualStore';
import TasksFiltersVisualStore from '../stores/TasksFiltersVisualStore';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AuthConsumer } from '../../authorization/AuthContext';
import { hasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { ColumnModel } from '../types';
import { Utils } from 'src/modules/common/misc/Utils';
import { ProjectsStore } from 'src/modules/common/stores';

const { Search } = Input;

type Props = {
    store: TasksGridVisualStore;
    filterStore: TasksFiltersVisualStore;
    createStore: TaskCreateVisualStore;
    projectsStore: ProjectsStore
};

const TaskListHeaderControls: React.FC<Props> = ({ store, createStore, filterStore, projectsStore }) => {
    const { widgetId, value, activityType, period, projectId } = useParams();
    const [searchParams] = useSearchParams();
    const activityPeriod = searchParams.get('period');

    let dragIndex = 0;
    const navigateTo = useNavigate();
    const handleBatchAssignDialogButtonClick = () => {
        if (store.allowBatchAssign) {
            store.setTaskAssignDialogVisible(true);
        }
    };

    const dynamicControls = () => {
        return (
            <div className="tasks-header-controls">
                <span className="selected-items">{store.selectedRows.length} selected tasks: </span>
                {store.selectedProject !== ALL_PROJECTS ?
                    <span className="header-control" onClick={() => store.setIsBulkUpdateDialogOpen(true)}>
                        <i className={'alpha-icon xs edit-native-blue'} />
                        Update
                    </span>
                    : null
                }
                <span
                    className={`header-control ${store.allowBatchAssign ? '' : 'disabled'}`}
                    onClick={handleBatchAssignDialogButtonClick}
                >
                    {store.commonUsersLoading
                        ? (<LoadingOutlined style={{ marginRight: 10 }} />)
                        : (<i className={`alpha-icon xs filter-my-tasks${store.allowBatchAssign ? '-active' : ''}`} />)}

                    Assign
                </span>
                {/* <span className="header-control">
                    <i className={`alpha-icon xs filter-all-tasks-active`}/>
                    Mark as completed
                </span> */}
                <Popconfirm
                    title={`Delete ${store.selectedRows.length} task(-s)?`}
                    onConfirm={store.deleteSelectedTasks}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <span className="header-control">
                        <i className={'alpha-icon xs action-remove'} />
                        Delete
                    </span>
                </Popconfirm>

                {/* <span className="header-control">
                    <i className={`alpha-icon xs action-more`}/>
                    More...
                </span> */}
            </div>
        );
    };

    const handleOnDrop = (index: number) => {
        dragIndex = index;
    };

    const handleOnHit = (args: OnHitEventArgs, index: number) => {
        store.handleColumnsReorder(dragIndex, index);
    };

    const actionsMenu: MenuProps = {
        items: [
            {
                key: 'save',
                label: (
                    <div
                        onClick={store.removeDefaultLayout}
                    >
                        <span style={{ width: 160, display: 'inline-block' }}>Reset layout</span>
                    </div>
                ),
                disabled: !Object.keys(store.userColumnsConfig).length
            },
            {
                key: 'reset',
                label: (
                    <div
                        onClick={filterStore.handleAllFiltersReset}
                    >
                        <span style={{ width: 160, display: 'inline-block' }}>Reset filters</span>
                    </div>
                ),
                disabled: !Object.keys(filterStore.columnsFilterDict).length || filterStore.isGridView
            }
        ]
    };

    const isVisibleColumn = (col: ColumnModel, permissions: string[]) => {
        if (!col.isMetaData) {
            return true;
        }
        const metadata = store.tableMetaData.find(t => t.title === col.name);
        return metadata!.isVisible || hasPermission(permissions, AppPermissions.CanAccessAdministration);
    };

    const columnsMenu = (permissions: string[]) => {
        return {
            items: store.filteredTaskColumns.filter(col => isVisibleColumn(col, permissions)).map((x, i) => (
                {
                    key: i,
                    label: (
                        <div
                            style={{ padding: '8px 0px' }}
                            key={x.name}
                            data-id-type={x.name}
                        >
                            <DraggableItemWrapper key={x.name} index={i} handleOnDrop={handleOnDrop} handleOnHit={handleOnHit}>
                                <div>
                                    <div className='draggable-item'>
                                        <i style={{ verticalAlign: 'middle' }} className="alpha-icon md dragger" />
                                        <span>{x.name}</span>
                                    </div>
                                    <Observer>{() => (
                                        <Switch onClick={(e, ev) => ev.stopPropagation()} checked={x.isVisible} onChange={(e) => store.handleVisibiltyOnChange(x.name, e)} />
                                    )}
                                    </Observer>
                                </div>
                            </DraggableItemWrapper>
                        </div>
                    )
                }))
        };
    };

    const handleFiltersReset = () => {
        navigateTo('/tasks');
        store.resetToDefaultProject();
        store.resetAllFilters();
    };


    const loadTasks = (excludeFilters?: boolean) => {
        if (widgetId && !value) {
            store.getTasksByGroupedWidgetId(widgetId, store.selectedProject);
        } else if (value) {
            store.getTasksByPieChartSection(widgetId!, store.selectedProject, value);
        } else if (activityType) {
            store.getTasksByActivityType(activityType, store.selectedProject, activityPeriod!);
        } else {
            store.loadTasks(!!excludeFilters);
        }
    };

    const handleMainTasksSwitchChange = (isEnabled: boolean) => {
        store.setIsMainTasksViewEnabled(isEnabled);
        loadTasks();
    };

    const handleDoneTasksSwitchChange = (isEnabled: boolean) => {
        store.setIsDoneTasksViewEnabled(isEnabled);
        loadTasks();
    };

    const handleProjectChange = (id: string) => {
        store.handleProjectChange(id, widgetId, value, activityType, period);
    };

    const handleSearchChange = (searchTerm: string) => {
        store.setSearchTerm(searchTerm);
        // If search is reset we don't ignore selected project and filters
        if (searchTerm) {
            store.loadTasks(true);
        } else {
            loadTasks(false);
        }
    };

    return (
        <>
            <div className="tasks-header-controls-container">
                <Row style={{ alignItems: 'center' }}>
                    <Col flex={2}>
                        <div className='projects-select'>
                            <Select
                                loading={!store.selectedProject || !projectsStore.projects}
                                showSearch
                                disabled={!!store.currentWidgetName && projectId !== ALL_PROJECTS}
                                optionFilterProp="children"
                                filterOption={Utils.filterOption}
                                value={store.selectedProject}
                                options={[{ value: ALL_PROJECTS, label: 'All Projects' }, ...projectsStore.projects?.map(p => ({ value: p.id, label: p.name })) || []]}
                                onChange={handleProjectChange}
                                className={store.selectedProject === ALL_PROJECTS ? 'all-projects' : ''}
                            />
                        </div>
                    </Col>
                    {store.currentWidgetName ? <Col><Button
                        className='widget-name-button'
                        type="primary"
                        onClick={handleFiltersReset}
                        data-id="button-current-widget"
                    >
                        <div className='widget-label'>
                            <Tooltip title={store.currentWidgetName}>
                                {store.currentWidgetName}
                            </Tooltip>
                        </div>
                        <Tooltip title='Reset widget'>
                            <i className={'alpha-icon xs filter-reset'} />
                        </Tooltip>
                    </Button></Col> : null}
                    {store.selectedRows && !!store.selectedRows.length ?
                        <Col>
                            {dynamicControls()}
                        </Col> : null}
                    <Col>
                        <div className='tasks-filter-switch'>
                            <Switch data-id="main-tasks" onChange={handleMainTasksSwitchChange} checked={store.isMainTasksViewEnabled} />
                            <span className='title'>Main tasks only</span>
                        </div>
                    </Col>
                    <Col>
                        <div className='tasks-filter-switch'>
                            <Switch
                                data-id="done-tasks"
                                onChange={handleDoneTasksSwitchChange}
                                checked={store.isDoneTasksViewEnabled}
                                disabled={!!store.currentWidgetName && store.isDoneTasksToggleDisabled}
                            />
                            <span className='title'>Include done tasks</span>
                        </div>
                    </Col>
                    {store.selectedProject !== ALL_PROJECTS && store.selectedProject ?
                        <Col>
                            <div className='tasks-filter-switch'>
                                <Switch data-id="metadata-view" onChange={store.setIsMetadataViewEnabled} checked={store.isMetadataViewEnabled} />
                                <span className='title'>Metadata view</span>
                            </div>
                        </Col> : null}
                    <Col>
                        <AuthConsumer>
                            {({ permissions }) =>
                                <Observer>
                                    {() => (
                                        <Dropdown
                                            trigger={['click']}
                                            menu={columnsMenu(permissions)}
                                            overlayClassName="alpha-dropdown-overlay task-columns-menu"
                                        >
                                            <Button data-id="button-columns" className="dropdown-btn" style={{ border: 'none', marginRight: 10 }} ghost>
                                                <i className="alpha-icon xs columns" />
                                                Columns
                                            </Button>
                                        </Dropdown>)}
                                </Observer>
                            }
                        </AuthConsumer>
                    </Col>
                    <Col>
                        <Search
                            data-id="search area"
                            placeholder="Search..."
                            value={store.searchTerm}
                            onChange={(e) => store.setSearchTerm(e.target.value)}
                            onSearch={handleSearchChange}
                            enterButton
                            maxLength={150}
                            className="alpha-search-input no-search-btn ghost"
                            prefix={<i className="alpha-icon xs table-search" style={{ marginRight: 10 }} />}
                            allowClear
                        />
                    </Col>
                    <Col>
                        <Dropdown
                            trigger={['click']}
                            menu={actionsMenu}
                            overlayClassName="alpha-dropdown-overlay"
                        >
                            <Button data-id="button-three-dots" className="dropdown-btn" style={{ border: 'none', marginRight: 10 }} ghost>
                                <i className="alpha-icon xs action-more-dots no-margin" />
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Button
                            className="new-task-btn"
                            style={{ flex: '0 0' }}
                            type="primary"
                            onClick={() => createStore.setNewTaskDialogVisible(true)}
                            loading={!createStore.projects || !createStore.projects.length || !createStore.currentProjectId}
                            data-id="button-new-task"
                        >
                            New task
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default observer(TaskListHeaderControls);