import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TaskStatus } from '../types';
import { Switch, Popconfirm, Button, Select, Tabs, Tooltip, Layout } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import TaskStatusesVisualStore from '../stores/TaskStatusesVisualStore';
import TaskStatusCreateDialog from './TaskStatusCreateDialog';
import TaskStatusEditDialog from './TaskStatusEditDialog';
import { Utils } from '../../common/misc/Utils';
import TaskStatusFlowEditor from './TaskStatusFlowEditor';
import { useForm } from 'antd/lib/form/Form';

const { Content } = Layout;

type Props = {
    store: TaskStatusesVisualStore
};

const TaskStatusesList: React.FC<Props> = ({ store }) => {
    const [currentTab, setCurrentTab] = React.useState('1');
    const [form] = useForm();
    React.useEffect(() => {
        if (store.selectedProjectId) {
            store.loadTaskTypes(store.selectedProjectId);
            store.loadActionDefinitions(store.selectedProjectId);
            store.loadUsers();
        }
    }, [store.selectedProjectId, store]);

    const columns: ColumnProps<TaskStatus>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string) => {
                return (<span data-id-cells="name" data-id-value={name}>{name}</span>);
            }
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (code: string) => {
                return (<span data-id-cells="name" data-id-value={code}>{code}</span>);
            }
        },
        {
            title: 'Users',
            dataIndex: 'assignedUserIds',
            key: 'assignedUserIds',
            render: (val: string[], record: TaskStatus) => {
                const text = record.assignedUserIds && record.assignedUserIds.length > 0 ? store.getStatusUserNames(record).join(', ') : 'All';
                return (
                    <Tooltip title={text} placement="topLeft">
                        <div data-id-cells="user" data-id-value={text} style={{
                            maxWidth: 'calc(100vw - 800px)',
                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                        }}>
                            {text}
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Actions',
            width: 200,
            render: (text: string, record: TaskStatus) => (
                <div className="row-actions-wrapper">
                    <Button data-id-cells="edit status" title="Edit" size="small" type="link" onClick={() => {
                        store.selectStatus(record);
                    }}>
                        <Tooltip title="Edit status" placement="bottom">
                            <i className="alpha-icon md table-action-edit" />
                        </Tooltip>
                    </Button>
                    <Popconfirm
                        id={`data-id-popconfirm-box-${record.name}`}
                        title="Are you sure you want to delete this status?"
                        onConfirm={() => {
                            store.deleteTaskStatus(record.id);
                        }}
                        placement="topRight"
                        disabled={record.isFinal || record.isDefault}
                    >
                        <Button data-id-cells="delete status" title="Delete" size="small" type="link" disabled={record.isFinal || record.isDefault}>
                            <Tooltip title="Delete status" placement="bottom">
                                <i className="alpha-icon md table-action-delete" />
                            </Tooltip>
                        </Button>
                    </Popconfirm>
                    <Switch
                        data-id-cells="is-active"
                        data-id-value={record.isEnabled}
                        style={{ visibility: 'visible', marginBottom: 18, marginLeft: 14 }}
                        checked={record.isEnabled} onChange={() => {
                            store.toggleTaskStatus(record.id);
                        }}
                    />
                </div>
            )
        }
    ];

    const statusesTable = (
        <Table
            data-id="table-List-list"
            className="alpha-table statuses"
            loading={store.taskStatusesLoading}
            columns={columns}
            dataSource={store.taskStatuses.filter(x => !x.isSubtaskStatus)}
            rowKey={(r) => r.id}
            pagination={false}
        />
    );

    const subtaskStatusesTable = (
        <>
            <h3 style={{ marginTop: 16 }}>Subtask statuses</h3>
            <Table
                data-id="table-List-list"
                className="alpha-table statuses"
                loading={store.taskStatusesLoading}
                columns={columns}
                dataSource={store.taskStatuses.filter(x => x.isSubtaskStatus)}
                rowKey={(r) => r.id}
                pagination={false}
            />
        </>
    );

    const tabItems = [
        {
            key: '1',
            label: <span data-id-type="Tab" data-id-value="List">List</span>,
            children: (
                <>
                    {store.selectedTaskType && statusesTable}
                    {store.selectedTaskType && store.taskType?.distinctSubtaskStatuses && subtaskStatusesTable}
                </>
            ),
        },
        {
            key: '2',
            label: <span data-id-type="Tab" data-id-value="Flow">Flow</span>,
            children: store.selectedTaskType && <TaskStatusFlowEditor form={form} store={store} />,
        },
    ];

    return (
        <Layout className="screen-size layout-with-table">
            <TaskStatusCreateDialog store={store} />
            <div className='header-wrapper'>
                <div className='title-wrapper'>
                    <div className="header-title">Task Statuses</div>
                </div>
                <Button
                    key={1}
                    data-id="submit"
                    type="primary"
                    size="large"
                    disabled={!store.selectedTaskType}
                    onClick={currentTab === '1' ? () => {
                        store.setNewStatusDialogVisible(true);
                    } :
                        () => {
                            form.validateFields().then(() => {
                                store.submitNewStatusDependencies();
                            }).catch(err => console.error(err));
                        }}
                >
                    {currentTab === '1' ? 'Add status' : 'Save'}
                </Button>
            </div>
            <TaskStatusEditDialog store={store} />
            <Layout>
                <Content>
                    <div className='projects-list-container' style={{ marginBottom: 10 }}>
                        <Select
                            options={store.projects?.map(p => ({ label: p.name, value: p.id })) || []}
                            placeholder='Select project'
                            onChange={store.setSelectedProjectId}
                            style={{ width: 300, marginRight: 10 }}
                            showSearch
                            filterOption={Utils.filterOption}
                        />
                        <Select
                            data-id="input-Name"
                            value={store.selectedTaskType}
                            onChange={store.setSelectedTaskType}
                            style={{ width: 300 }}
                            placeholder='Select type'
                            showSearch
                            filterOption={Utils.filterOption}
                            options={store.taskTypes ? store.taskTypes.map(p => ({ label: p.name, value: p.id })) : []}
                        />
                    </div>
                    {/* <div>
                    </div> */}
                    <Tabs onChange={(key) => setCurrentTab(key)} className='statuses-tabs-wrapper' items={tabItems}/>
                </Content>
            </Layout>

        </Layout>
    );
};

export default observer(TaskStatusesList);