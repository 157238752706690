import { TaskService } from '../services';
import { action, runInAction, observable, computed, makeObservable } from 'mobx';
import { ErrorStore } from '../../common/stores';
import { TaskCommentModel, TaskModel } from '../types';
import { TaskViewVisualStore } from '.';
import { Utils } from '../../common/misc/Utils';

export default class TaskCommentsStore {
    taskNewComment: string;

    postingComment: boolean = false;

    editingCommentId: string | undefined = undefined;

    editingCommentBody: string = '';

    comments: TaskCommentModel[] = [];

    commentsLoading: boolean = false;

    commentFromDialog: string;

    constructor(private tasksService: TaskService, private errorStore: ErrorStore, private tasksVisualStore: TaskViewVisualStore, taskId?: string) {
        makeObservable<TaskCommentsStore>(this, {
            taskNewComment: observable,
            postingComment: observable,
            editingCommentId: observable,
            editingCommentBody: observable,
            comments: observable,
            commentsLoading: observable,
            canPostComment: computed,
            canEditComment: computed,
            currentUserId: computed,
            loadComments: action.bound,
            unloadComments: action.bound,
            setTaskNewComment: action.bound,
            setEditingCommentBody: action.bound,
            setCommentFromDialog: action.bound,
            postComment: action.bound,
            postCommentFromDialog: action.bound,
            toggleCommentEdit: action.bound,
            getTaskByAlphaId: action.bound,
            openTaskTab: action.bound,
            updateComment: action.bound,
            removeComment: action.bound
        });
        if (taskId) {
            this.loadComments(taskId);
        }
        // this.tasksVisualStore.taskCommentChanges.subscribe(this.updateComments);
        // this.tasksVisualStore.taskCommentDeltionObs.subscribe(this.removeCommentHandler);
    }


    get canPostComment() {
        return !!this.taskNewComment && this.taskNewComment.replace(Utils.htmlTags, '').trim().length > 0 && !this.postingComment;
    }

    get canEditComment() {
        return !!this.editingCommentBody && this.editingCommentBody.replace(Utils.htmlTags, '').trim().length > 0 && !this.postingComment;
    }

    get currentUserId() {
        return this.tasksVisualStore.currentUserId;
    }

    async loadComments(taskId: string) {
        try {
            this.commentsLoading = true;
            const result = await this.tasksService.getTaskComments(taskId);
            runInAction(() => {
                this.comments = result;
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.commentsLoading = false;
            });
        }
    }

    unloadComments() {
        this.comments = [];
        this.taskNewComment = '';
    }

    setTaskNewComment(comment: string) {
        this.taskNewComment = comment;
    }

    setEditingCommentBody(comment: string) {
        this.editingCommentBody = comment;
    }

    setCommentFromDialog(comment: string) {
        this.commentFromDialog = comment;
    }


    async postComment(taskId: string) {
        if (!this.taskNewComment) {
            return;
        }
        await this.postNewComment(taskId, this.taskNewComment);
        this.tasksVisualStore.loadTaskActivities();

    }

    async postCommentFromDialog(taskId: string) {
        if (!this.commentFromDialog) {
            return;
        }
        await this.postNewComment(taskId, this.commentFromDialog);
    }

    toggleCommentEdit(commentId: string | undefined) {
        if (!commentId) {
            this.editingCommentId = undefined;
            this.editingCommentBody = '';
        } else {
            const comment = this.comments.find(c => c.id === commentId);
            if (comment) {
                this.editingCommentId = commentId;
                this.editingCommentBody = comment.body;
            }
        }
    }

    async getTaskByAlphaId(alphaId: string) {
        return this.tasksService.getTaskById(null, alphaId);
    }

    openTaskTab(task: TaskModel) {
        this.tasksVisualStore.expandTask(task);
    }

    async updateComment(taskId: string) {
        if (this.editingCommentId && this.editingCommentBody) {
            runInAction(() => {
                this.postingComment = true;
            });

            const resp = await this.tasksService.updateComment(this.editingCommentId, this.editingCommentBody);
            this.loadComments(taskId);
            this.tasksVisualStore.loadTaskActivities();
            resp.map(() => {
                runInAction(() => {
                    this.editingCommentId = undefined;
                    this.editingCommentBody = '';
                });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => this.errorStore.addError(err.data));

            runInAction(() => {
                this.postingComment = false;
            });
        }
    }

    async removeComment(commentId: string) {
        const resp = await this.tasksService.deleteComment(commentId);
        const index = this.comments.findIndex(c=> c.id === commentId);
        const comments = this.comments.slice();
        comments.splice(index, 1);
        this.comments = comments;
        this.tasksVisualStore.loadTaskActivities();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resp.mapErr((err: any) => this.errorStore.addError(err.data));
    }

    // private updateComments(comment: TaskCommentModel) {
    //     if (this.comments && this.comments.length > 0) {
    //         let taskComment = this.comments.find(c => c.id === comment.id);

    //         if (taskComment) {
    //             var index = this.comments.indexOf(taskComment);
    //             runInAction(() => {
    //                 let newList = this.comments.slice();
    //                 newList[index] = comment;
    //                 this.comments = newList;
    //             });
    //         } else {
    //             runInAction(() => {
    //                 this.comments.unshift(comment);
    //             });
    //         }
    //     }
    // }

    // private removeCommentHandler(commentId: string) {
    //     if (this.comments && this.comments.length > 0) {
    //         this.comments = this.comments.filter(c => c.id !== commentId);
    //     }
    // }

    private async postNewComment(taskId: string, comment: string) {
        runInAction(() => {
            this.postingComment = true;
        });
        const resp = await this.tasksService.addComment(taskId, comment);
        resp.map(() => {
            this.loadComments(taskId);
            runInAction(() => {
                this.taskNewComment = '';
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));

        runInAction(() => {
            this.postingComment = false;
        });
    }
}