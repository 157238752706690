/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExtraDateFilter } from '../../administration/types/DashboardWidgetMeta';
import { AlphaId, DefaultColumn, MetadataColumn, TaskListModel } from '../../tasks/types';
import { Notification } from '../types/Notification';

export enum PackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Uploading = 'Uploading',
    Broken = 'Broken'
}

export enum PackageSetType {
    Conjunction = 'Conjunction',
    Folder = 'Folder'
}

export type AnchorResult = {
    id: string;
    keyword: string;
    aliases: string[]
};

export type NamedEntity = {
    start: number;
    end: number;
    text: string
};

export type ApplicationSession = {
    id: string;
    packageId: string;
    projectId: string;
    runtimeSessionId: string;
    created: string;
    updated: string;
    applicationDefinitionId: string;
    error: string;
    applicationExtension: string;
    applicationName: string;
    applicationSettings: {};
    iotaApplication: string;
    packageName: string;
    projectName: string;
    state: SessionStates;
    packageSetId?: string;
    userId?: string;
    isBulkSession?: boolean;
    isReloadable?: boolean;
    updatedBy?: string
};

export type SessionStates = 'Pending' | 'Finished' | 'WaitingAction' | 'Aborted' | 'NotStarted' | 'Archived';

export type PackageResult = {
    id: string;
    fileName: string;
    filePath: string;
    projectId: string;
    contentType: string;
    state: PackageStateResult;
    operationState: string[];
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    source: string;
    packageSetId?: string
};

export enum PackageListItemType {
    Package = 'package',
    PackageSet = 'packageSet'
}

export type PackageListItemModel = {
    id: string;
    fileName: string;
    projectName: string;
    projectId: string;
    state: PackageStateResult;
    userTags: string[];
    fileSizeBytes: number;
    uploadedTime: string;
    indexDate: string;
    type?: PackageListItemType;
    packages?: PackageResult[]
};

export type PackageSetResult = {
    id: string;
    name: string;
    projectId: string;
    packageIds: string[];
    type: PackageSetType;
    uploadTime?: string;
    state: PackageStateResult;
    packages: PackageResult[]
};

export type ProjectForCurrentUser = {
    id: string;
    name: string;
    isAdmin: boolean;
    owner: string
};

export type Project = {
    id: string;
    name: string;
    owner: string
};

export type TaskListLayoutResult = {
    id: string;
    userId: string;
    columnsConfig: {[key: string]: ColumnResult[]};
    selectedProjectId: string | null;
    pinnedTasks: PinnedTask[] | null
};

export type PinnedTask = {
    id: string;
    alphaId: AlphaId;
    name: string;
    parentTask: TaskListModel
};

export type ColumnResult = MetadataColumn | DefaultColumn;

export type ColumnsFilter = {
    fieldType: 'General' | 'Metadata';
    entityReference: string;
    operator: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[];
    extraFilters:  {
        extraDateFilter?: ExtraDateFilter
    };
    saved: boolean;
    isWidgetFilter: boolean
};

export type DefaultProjectRes = {
    id: string;
    defaultProject: string
};

export type ApplicationCapabilities = {
    isBulkProcessing: boolean;
    isReloadingDisabled: boolean
};

export type ApplicationDefinition = {
    id: string;
    name: string; 
    applicationId: string; 
    workflowId: string; 
    projectId: string; 
    extension: string;
    state: string;
    meta: string;        
    settings?: string;
    iconFileId?: string;
    bindings: {
        inputId: string;
        type: string;
        value: unknown
    }[];
    settingValues?: {[id: string]: unknown};
    iconUrl?: string;
    capabilities?: ApplicationCapabilities
};

export type ApplicationDefinitionSubgroup = {
    id: string;
    name: string;
    applicationDefinitionIds: string[];
    subGroups: ApplicationDefinitionSubgroup[]
};

export type PackageChanges = {
    id: string;
    state: PackageStateResult;
    fileName: string;
    fileSizeBytes: number;
    uploadedTime: string;
    userTags: string[];
    projectId: string;
    filePath: string;
    indexDate: string;
    packageSetId?: string;
    source?: string
};


export type PackageSetSessionPackage = {
    packageId: string;
    packageName: string;
    state: string;
    createdTime: string;
    updatedTime: string
};

export type GetTaskListLayoutForUserResult = {
    getTaskListLayoutByUser: TaskListLayoutResult
};

export type NotificationsResult = {
    getNotifications: Notification[]
};

export type WidgetDataItem = {
    text: string; value: string
};

export type ComplexWidgetDataItem = {
    text: string; value: unknown
};

export type PackageSetItemModel = {
    id: string;
    fileName: string;
    contentType: string;
    fileSizeBytes: number;
    indexDate: string
};

export type PackageSetItemsResult = {
    getPackagesFromSet: PackageSetItemModel[]
};

export enum WidgetType { Combobox = 'combobox', 
    DatePicker =  'datepicker',
    Input = 'input',
    Checkbox = 'checkbox', 
    Radio = 'radio',
    ListBox = 'listbox',
    VisualTable = 'visualtable',
    ResultTable= 'resulttable',
    ContainerInputChild = 'containerInputChild'
}


export type FormInputFieldData = {
    p: number; // Page number
    x: number;
    y: number;
    w: number; // Width
    h: number; // Height
    pw: number; // Page width
    ph: number; // Page height
    bt: string; // Block type
    pId?: string; // Package Id
    nodeHierarchy?: string
};

export type UserProfile = {
    userId: string;
    userName: string;
    name: string;
    timezone: string;
    avatarIconFileId: string;
    firstName: string;
    lastName: string;
    email: string;
    isDomainUser: boolean;
    isDeleted: boolean;
    lastActivityTime: Date
};

export type UserProfileResult = {
    getUserProfile: UserProfile
};