import { Button, Collapse } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { TaskViewVisualStore } from '../../../tasks/stores';
import { Utils } from '../../misc/Utils';

type TaskDescriptionSectionProps  = {
    store: TaskViewVisualStore
};

const TaskDescriptionSection: React.FC<TaskDescriptionSectionProps> = ({store, }) => {
    const [isEditingDescription, setIsEditingDescription] = React.useState(false);
    const description = store.taskPreview?.description;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getExpandIcon = (panelProps: any) => {
        return (
            <i style={!panelProps.isActive ? { transform: 'rotate(-90deg)', transition: 'transform 0.24s' } :
                { transition: 'transform 0.24s' }} className="alpha-icon xxxs arrow-down-icon" />
        );
    };

    const collapseItems = [
        {
            key: 'description',
            label: 'Description',
            children: (
                <div className="description-input-wrapper">
                    {isEditingDescription ? (
                        <ReactQuill
                            modules={Utils.getQuilModules()}
                            readOnly={store.taskFormLocked}
                            value={store.newDescription || ''}
                            onChange={store.setNewDescription}
                            className={'comment-input alpha-quill tb-bottom has-text'}
                        />
                    ) : (
                        <span
                            style={{display: 'block', marginRight: 12, wordBreak: 'break-word', fontSize: 14}}
                            dangerouslySetInnerHTML={{
                                __html: description && description !== '<p><br></p>' ? description : '<div class="empty-message">There is no description.</div>'
                            }}
                        />
                    )}
                    <div className="description-button-container">
                        {isEditingDescription || store.taskFormLocked ? null : (
                            <Button size="small" type="link" onClick={() => setIsEditingDescription(true)}>
                                <i className="alpha-icon xs edit-pen" />
                            </Button>
                        )}
                    </div>
                    {isEditingDescription ? (
                        <div style={{ marginTop: 12, textAlign: 'right' }}>
                            <Button
                                onClick={() => {
                                    setIsEditingDescription(false);
                                    store.setNewDescription(store.taskPreview?.description);
                                }}
                                style={{ marginRight: 12 }}
                            >
                            Discard
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsEditingDescription(false);
                                    store.saveNewDescription();
                                }}
                            >
                            Save
                            </Button>
                        </div>
                    ) : null}
                </div>
            ),
        }];

    return (
        <div>
            <Collapse
                items={collapseItems}
                bordered={false}
                activeKey={store.activeSectionKeys.includes('description') ? 'description' : undefined}
                className="alpha-task-section-collapse"
                expandIcon={getExpandIcon}
                onChange={(keys: string[]) => store.setSectionActiveKey('description', !!keys[0])}
            />
        </div>
    );
};

export default observer(TaskDescriptionSection);