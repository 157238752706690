import * as React from 'react';
import { Layout } from 'antd';
import { Navigate, Route, Routes } from 'react-router';
import { useStore } from '../../common/stores/context';
import { context as tasksContext } from '../../tasks/stores';
import { observer } from 'mobx-react-lite';
// import { PushServiceStatusBar } from './PushServiceStatusBar';
import documentRoute from '../../documents/routes';
import notificationRoute from '../../notifications/routes';
import dashboardRoute from '../../home/routes';
import { SideMenu } from './SideMenu';
import Sider from 'antd/lib/layout/Sider';
import  * as dashboardContext  from '../../home/stores/context';
import adminRoute from '../../administration/routes';
import taskTypeRoute from '../../task_types/routes';
import taskStatusesRoute from '../../task_statuses/routes';
import taskTemplatesRoute from '../../task_templates/routes';
import projectsRoute from '../../../modules/projects/routes';
import { AuthConsumer } from '../../authorization/AuthContext';
import TasksRoute from '../../tasks/routes';
import ErrorNotification from './ErrorNotification';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import reportsRoute from '../../reports/routes';

const {  Content } = Layout;

const MainLayout: React.FC = () => {
    const rootStore = useStore();
    const [sideMenuCollapsed, setSideMenuCollapsed] = React.useState(false);

    const appVersion = process.env.REACT_APP_VERSION === '0.0.0.0' ? 'local-dev' : `v${process.env.REACT_APP_VERSION}`;
    const siderWidth = sideMenuCollapsed  ? 56 : 250;

    return (
        <tasksContext.TasksStoreProvider>
            <Layout className="main-layout">
                {/* {rootStore.projectsStore.isPushServiceConnected ? <></> : <PushServiceStatusBar/>} */}
                <Sider 
                    className={`side-menu-container ${sideMenuCollapsed ? 'collapsed': ''}`} 
                    width={siderWidth}
                >
                    <span className="sider-collapser" onClick={() => {
                        setSideMenuCollapsed((prevState) => !prevState); 
                    }}>
                        <i className="alpha-icon md close-panel-icon" />
                    </span>
                    <SideMenu tabsStore={rootStore.tabsStore} isCollapsed={sideMenuCollapsed}/>
                </Sider>
                <div style={{height: '100%', display: 'flex', flexDirection: 'column', width: `calc(100% - ${siderWidth}px)`}}>
                    <Content style={{padding: '5px 30px 0px 30px', overflowY: 'auto', overflowX: 'hidden'}}>
                        <AuthConsumer>
                            {({ permissions }) => (
                                <dashboardContext.DashboardStoreProvider>
                                    <TasksRoute isSideMenuCollapsed={sideMenuCollapsed} />
                                    <Routes>       
                                        {documentRoute()}
                                        {notificationRoute()}  
                                        {dashboardRoute()}
                                        {reportsRoute(permissions)}
                                        <Route path='/' element={
                                            <Navigate replace to={'/dashboard'} />
                                        }/>
                                    </Routes>  
                                    <HasPermission permissionClaim={AppPermissions.CanAccessAdministration}>
                                        <Routes>
                                            {adminRoute(permissions)}
                                            {taskTypeRoute()}
                                            {taskStatusesRoute()}
                                            {taskTemplatesRoute()}
                                            {projectsRoute(permissions)}
                                        </Routes>   
                                    </HasPermission>
                                </dashboardContext.DashboardStoreProvider>
                            )}
                        </AuthConsumer>
                    </Content>
                    <ErrorNotification/>
                    <div className="app-version-label">{`© Alpha Tasks. ${appVersion}`}</div>
                </div>
            </Layout>
        </tasksContext.TasksStoreProvider>
    );
};

export default observer(MainLayout);