import { Dropdown, Button } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { AuthConsumer } from '../../authorization/AuthContext';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';
import DashboardStore from '../stores/DashboardStore';
import WidgetBase from '../models/WidgetBase';
import { ALL_PROJECTS } from '../../tasks/screens/TasksPage';
import { ProjectRole } from '../../administration/types/UserModel';

type Props = {
    dashboardStore: DashboardStore;
    widget: WidgetBase;
    openEditWidgetModal: (id: string) => void
};

const WidgetEditor: React.FC<Props> = ({ widget, dashboardStore, openEditWidgetModal}) => {

    const getActionsMenu = (permissions: string[], projectRoles: ProjectRole[]) => {
        return (
            {
                items: [
                    {

                        key: 'edit',
                        label: (
                            <div 
                                onClick={() => openEditWidgetModal(widget.id)} 
                            >
                                <i className="alpha-icon edit-pen xs" />
                                <span>Edit</span> 
                            </div>
                        ),
                        disabled: !hasPermission(permissions, AppPermissions.CanAccessAdministration, projectRoles, widget.projectId || ALL_PROJECTS)

                    },
                    {
                        key: 'remove',
                        label: (
                            <div 
                                onClick={() => dashboardStore.deactivateWidget(widget.id)}
                            >
                                <i className="alpha-icon danger-delete-icon xs" />
                                <span>Remove</span> 
                            </div>
                        ),
                    }
                ]
            });
    };

    return (
        <AuthConsumer>
            {({ permissions, projectRoles }) => (
                <Dropdown
                    trigger={['click']}
                    menu={getActionsMenu(permissions, projectRoles)}
                    overlayClassName="alpha-dropdown-overlay widget-editor"
                >
                    <Button data-id="button-three-dots" className="dropdown-btn editor" ghost>
                        <i className="alpha-icon xs action-more-dots"/>
                    </Button>
                </Dropdown>)}
        </AuthConsumer>

    );
};

export default observer(WidgetEditor);
