import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TaskCommentsStore } from '../stores';
import ReactQuill from 'react-quill';
import { Utils } from '../../common/misc/Utils';
import { Form } from 'antd';

type Props = {
    commentsStore: TaskCommentsStore;
    value: string
};
const FormItem = Form.Item;

const CommentInDialog: React.FC<Props> = ({commentsStore, value }) => {
    return (
        <FormItem
            colon={false}
            name="comment"
        >
            <div className="comment-input-container">
                <div className="comment-input-wrapper" data-id={'input-comment'}>
                    <ReactQuill
                        modules={Utils.getQuilModules()}
                        onChange={commentsStore.setEditingCommentBody}
                        className={'comment-input alpha-quill'}
                        value={value}
                    />
                </div>
            </div>
        </FormItem>
    );
};

export default observer(CommentInDialog);

