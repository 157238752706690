import { TaskService } from '../services';
import { ErrorStore, NotificationStore, TabsStore, UserProfileStore } from '../../common/stores';
import { TaskModel, PackagePreviewModel, AttachmentUpdateModel, TaskActivity, FollowersChangeModel, 
    AssignedToChangesModel, TaskPrivacyType, TaskLink, 
    TaskLookupItem, ParentTaskMeta, EmailMetadata, TaskListModel, TaskCommentModel, QueryRequestData, ActionCompletedResult, 
    ActionEvent,
    ActionTriggeredResult} from '../types';
import { observable, computed, action, runInAction, makeObservable, reaction } from 'mobx';
import { ApplicationSession, PackageChanges, PinnedTask } from '../../common/services/types';
import { RcFile } from 'antd/lib/upload';
// import { ApplicationSessionChanges } from '../../sessions/types';
import { message } from 'antd';
import { ActionDefinition } from '../../administration/types/Actions';
import TasksRootStore from './TasksRootStore';
import { Utils } from '../../common/misc/Utils';
import { TabModel } from '../../common/types/TabModel';
import { Observable, Subject, Subscription } from 'rxjs';
import tasksPushClient from '../services/TaskPushClient';
import { filter } from 'rxjs/operators';
import { Notification } from '../../common/types/Notification';
import _ from 'lodash';
import { TaskStatus } from '../../task_statuses/types';
import ClientActionRunner from '../../administration/misc/ClientActionHandler';
import ActionHelper from 'src/modules/common/misc/ActionHelper';

const FIELD_UPDATE_DEBOUNCE_TIME = 1500;

export enum AccessType {
    Everyone = 'Everyone',
    LimitedAccess = 'Limited access',
    OnlyMe = 'Only me'
}

export enum ScrollToSections {
    Subtasks = 'Subtasks',
    Attachments  = 'Attachments '
}

export default class TaskViewVisualStore {
    taskPreview: TaskModel & {isSubtask: boolean} | undefined = undefined;

    taskAttachments: PackagePreviewModel[] = [];

    attachmentsLoading: boolean = false;

    selectedAttachments: string[] = [];

    taskActivity: ApplicationSession[] = [];

    taskActivityLoading: boolean = false;

    previewAttachment: PackagePreviewModel | undefined = undefined;

    taskTypesLoading: boolean = false;

    taskStatusesForType: TaskStatus[] = [];

    taskStatusesLoading: boolean = false;

    editingTitle: boolean = false;

    newTitle: string;
    
    taskActivitiesLoading: boolean = false;

    taskActivities: TaskActivity[] = [];

    taskPrefix: string | undefined = undefined;

    taskPrefixLoading: boolean = false;

    attachmentsActiveKey: string;    

    availableActionsLoading: boolean = false;

    availableActions: ActionDefinition[] = [];

    taskLinksLoading: boolean = false;

    taskLinks: TaskLink[] = [];
    
    taskLookups: TaskLookupItem[] = [];

    selectedLink: string | undefined | null = undefined;

    selectedAction: string | undefined = undefined;

    selectedPackageForAction: string | undefined = undefined;

    emailTaskToUserDialogVisible: boolean = false;

    sendingEmailToUsers: boolean = false;

    newDescription: string | undefined = undefined;

    loadingUsers: boolean = false;

    taskDrawerIsResizing: boolean = false;

    isSubtaskLoadingInProgress: boolean = false;

    attachmentChangesSubject = new Subject<AttachmentUpdateModel>();

    followersChangesSubject = new Subject<FollowersChangeModel>();

    taskCommentChanges = new Observable<TaskCommentModel>();

    taskCommentDeltionObs = new Observable<string>();

    packageStatusChangeObs = new Observable<PackageChanges>();

    taskFieldUpdate: Observable<TaskModel>;

    debouncedFieldUpdate: Function;

    taskDrawerWidth: number = 404;

    scrollToSections: ScrollToSections | undefined;

    activeSectionKeys: string[] = [];

    isActionLoading: boolean;

    actionEvents: ActionEvent[] = [];

    actionEventsLoading: boolean = false;

    private taskChanges: Observable<TaskModel>;

    private completedActions: Observable<ActionCompletedResult>;

    private triggeredActions: Observable<ActionTriggeredResult>;

    private subscriptions: Subscription[]  = [];

    constructor(
        private tasksService: TaskService,
        private tabsStore: TabsStore,
        private errorStore: ErrorStore,
        private userProfileStore: UserProfileStore,
        private notificationStore: NotificationStore,
        private tasksRootStore: TasksRootStore,
        private actionRunner: ClientActionRunner) {
        makeObservable<TaskViewVisualStore, 
        'downloadAttachment' | 
        'handleAttachmentUpdates' | 
        'loadTaskActivities' |
        'loadAvailableActions' | 
        'loadActionEvents' |
        'loadTaskLinks' |
        'loadTaskPrefix' | 
        'updateTaskField'|
        'updateAttachments' |
        'handleActionCompleted'
        >
        (this, {
            taskPreview: observable,
            taskAttachments: observable,
            attachmentsLoading: observable,
            selectedAttachments: observable,
            taskActivity: observable,
            taskActivityLoading: observable,
            previewAttachment: observable,
            taskTypesLoading: observable,
            taskStatusesForType: observable,
            taskStatusesLoading: observable,
            editingTitle: observable,
            newTitle: observable,
            taskActivitiesLoading: observable,
            taskActivities: observable,
            taskPrefix: observable,
            taskPrefixLoading: observable,
            availableActionsLoading: observable,
            availableActions: observable,
            taskLinksLoading: observable,
            taskLinks: observable,
            taskLookups: observable,
            selectedLink: observable,
            selectedAction: observable,
            selectedPackageForAction: observable,
            emailTaskToUserDialogVisible: observable,
            sendingEmailToUsers: observable,
            newDescription: observable,
            loadingUsers: observable,
            taskDrawerIsResizing: observable,
            taskDrawerWidth: observable,
            attachmentsActiveKey: observable,
            activeSectionKeys: observable,
            isSubtaskLoadingInProgress: observable,
            scrollToSections: observable,
            isActionLoading: observable,
            actionEvents: observable,
            actionEventsLoading: observable,
            taskStatusIdsFiltered: computed,
            allUsersFullNameResolver: computed,
            currentUserId: computed,
            previewEmailMetadata: computed,
            canRunApplications: computed,
            taskFormLocked: computed,
            taskDisplayName: computed,
            trimmedTaskName: computed,
            isFollowingTask: computed,
            tasks: computed,
            filteredAssignToUsers: computed,
            selectedAttachmentObjects: computed,
            hasDueDate: computed,
            usersInProject: computed,
            userProfiles: computed,
            taskTypes: computed,
            taskType: computed,
            metadataDefinitions: computed,
            metadataDefinitionsLoading: computed,
            isFollowingCurrentTask: computed,
            taskPreviewDisplayName: computed,
            showPreviewDialog: computed,
            userProfilePictures: computed,
            taskPreviewTypes: computed,
            tasksLoading: computed,
            filteredAvailableTaskActions: computed,
            getAttachmentName: action.bound,
            getAvailableAttachmentActions: action.bound,
            seletedActionModel: computed,
            setEmailTaskToUserDialogVisible: action.bound,
            setNewDescription: action.bound,
            handleAssignedToChange: action.bound,
            handleFollowersChange: action.bound,
            setPreviewAttachment: action.bound,
            toggleAttachmentSelection: action.bound,
            handleAssignToSave: action.bound,
            updateField: action.bound,
            handlePrivacyChange: action.bound,
            saveNewDescription: action.bound,
            setEditingTitle: action.bound,
            setNewTitle: action.bound,
            appendFilesToPackageSet: action.bound,
            removeSelectedAttachments: action.bound,
            removeAttachment: action.bound,
            loadTaskAttachments: action.bound,
            getStatusDisplayName: action,
            copyTaskUrl: action.bound,
            loadTaskStatusesForType: action.bound,
            getTaskStatusDisplay: action.bound,
            handleAttachmentDownload: action.bound,
            toggleTaskFollowing: action.bound,
            emailTaskToUsers: action.bound,
            handleStatusChangeSave: action.bound,
            deleteTask: action.bound,
            getTaskTitle: action.bound,
            downloadAttachment: action.bound,
            handleAttachmentUpdates: action.bound,
            loadTaskActivities: action.bound,
            loadActionEvents: action.bound,
            loadTaskPrefix: action.bound,
            loadAvailableActions: action.bound,
            loadTaskLinks: action.bound,
            setInitialLookup: action.bound,
            setSelectedLink: action.bound,
            lookupTasks: action.bound,
            createTaskLink: action.bound,
            deleteTaskLink: action.bound,
            setSelectedAction: action.bound,
            updateTaskField: action.bound,
            setQueriedStatus: action.bound,
            updateMetadataField: action.bound,
            clearTaskLookups: action.bound,
            handlePinClick: action.bound,
            setTaskDrawerIsResizing: action.bound,
            hanldeAssignToChange: action.bound,
            openParentTask: action.bound,
            setAttachmentsLoading: action.bound,
            updateAttachments: action.bound,
            loadPreviewTaskAttachments: action,
            handleTypeChange: action.bound,
            setSectionActiveKey: action.bound,
            setSubtaskLoadingInProgress: action,
            setIsActionLoading: action,
            handleActionCompleted: action.bound
        });

        // this.projectsStore.sessionChanges.subscribe((s) => {
        //     this.updateSessionState(s); 
        // });
        this.attachmentChangesSubject.subscribe(this.handleAttachmentUpdates);
        this.followersChangesSubject.subscribe(this.handleFollowersChange);
        // TODO: uncomment after merging to one preview store
        // this.taskVisualStore.assignedToChangesSubject.subscribe(this.handleAssignedToChange);
        
        const {pushClient} = tasksPushClient;

        const tasksObs = pushClient.createTaskListener().publish();
        this.taskChanges = tasksObs.map(r => r);
        tasksObs.connect();
        this.taskChanges.subscribe(() => this.tasksRootStore.loadTasks());

        const taskNotificationsObs = pushClient.createTaskNotificationsListener().publish();
        const filterByType = filter<Notification>(n => n.notificationType !== 'FailedTask');
        this.subscriptions.push(taskNotificationsObs.pipe(filterByType).subscribe(this.notificationStore.addNotification));        
        taskNotificationsObs.connect();

        const taskCommentChangesObs = pushClient.createTaskNewCommentListener().publish();
        this.taskCommentChanges = taskCommentChangesObs;
        taskCommentChangesObs.connect();

        const taskCommentDeletionObs = pushClient.createTaskCommentDeleteListener().publish();
        this.taskCommentDeltionObs = taskCommentDeletionObs;
        taskCommentDeletionObs.connect();

        
        const packageStatusChangeObs = pushClient.createPackageChangeListener().publish();
        this.packageStatusChangeObs = packageStatusChangeObs;
        packageStatusChangeObs.connect();
        this.subscriptions.push(this.packageStatusChangeObs.subscribe(this.updateAttachments));

        const updatedTaskObs = pushClient.updateTaskListener().publish();
        this.taskFieldUpdate = updatedTaskObs.map(r => r);
        updatedTaskObs.connect();
        this.subscriptions.push(this.taskFieldUpdate.subscribe(this.updateTaskField));

        const completedActions = pushClient.createActionCompletedListener().publish();
        this.completedActions = completedActions;
        completedActions.connect();
        this.subscriptions.push(this.completedActions.subscribe(this.handleActionCompleted));

        const triggeredActions = pushClient.createActionTriggeredListener().publish();
        this.triggeredActions = triggeredActions;
        triggeredActions.connect();
        this.subscriptions.push(this.triggeredActions.subscribe(this.handleActionTriggered.bind(this)));

        this.debouncedFieldUpdate = _.debounce(this.updateField, FIELD_UPDATE_DEBOUNCE_TIME);
        reaction(() => this.tasksRootStore.projects, (p, r, d) => {
            if (this.tasksRootStore.projects) {
                this.tasksRootStore.loadTaskTypes();
            }
            d.dispose();
        });
    }

    get taskStatusIdsFiltered() {
        if (this.taskStatusesForType && this.taskType) {
            const currentStatus = this.taskStatusesForType.find(s => s.id === this.taskPreview?.status);

            let taskStatusesFiltered = this.taskStatusesForType.slice();

            if (!currentStatus?.transitions?.length) {
                return [];
            }

            // Show main task/subtask statuses only if 'Distinct subtask statuses' is set on task type:
            if (this.taskType.distinctSubtaskStatuses) {
                taskStatusesFiltered = taskStatusesFiltered.filter(s => s.isSubtaskStatus === this.taskPreview!.isSubtask);
            }
            
            // If there are task transitions, show only allowed target statuses
            if (currentStatus?.transitions?.length) {
                taskStatusesFiltered = taskStatusesFiltered.filter(s => currentStatus.transitions!.find(st => st.targetStatusId === s.id));
            }

            if (this.currentUserId) {
                taskStatusesFiltered = taskStatusesFiltered.filter(s => !s.assignedUserIds || !s.assignedUserIds.length || s.assignedUserIds.indexOf(this.currentUserId) !== -1);
            }

            return taskStatusesFiltered.map(t => t.id);
        }

        return[];
    }

    get allUsersFullNameResolver() {
        return this.tasksRootStore.allUsersFullNameResolver;
    }

    get userProfilePictures() {
        return this.tasksRootStore.userProfilePictures;
    }

    get showPreviewDialog() {
        return !!this.taskPreview;
    }

    get currentUserId() {
        return this.tasksRootStore.currentUserId;
    }

    get previewEmailMetadata() {
        if (this.taskPreview) {
            return this.parseEmailMetadata(this.taskPreview);
        } else {
            return undefined;
        }
    }

    get canRunApplications() {
        return !!this.taskPreview && this.selectedAttachments && this.selectedAttachments.length && !this.getStatusById(this.taskPreview.status)?.isFinal;
    }
    
    get taskFormLocked() {
        return (!!this.taskPreview && !!this.taskPreview.statusName && !!this.getStatusById(this.taskPreview.status)?.isFinal);
    }

    get hasDueDate() {
        return !!this.taskPreview?.dueDate;
    }

    get taskDisplayName() {
        if (this.taskPreview) {
            return this.taskPreview.alphaId ? `${this.taskPreview.alphaId} - ${this.taskPreview.name}` : this.taskPreview.name;
        }

        return null;
    }

    get trimmedTaskName() {
        if (this.taskPreview && this.taskPreview.name && this.taskDisplayName) {
            return Utils.trimTitleString(this.taskDisplayName);
        }
        return null;
    }

    get isFollowingTask() {
        return this.taskPreview!.followers ? this.taskPreview!.followers.includes(this.currentUserId) : false;
    }

    get tasks() {
        return this.tasksRootStore.tasks;
    }

    get metadataDefinitions() {
        return this.tasksRootStore.metadataDefinitions;
    }

    get metadataDefinitionsLoading() {
        return this.tasksRootStore.metadataDefinitionsLoading;
    }

    get usersInProject() {
        return this.tasksRootStore.usersInProject;
    }

    get userProfiles() {
        return this.tasksRootStore.userProfiles;
    }

    get filteredAssignToUsers() {
        const hiddenUsers = this.usersInProject.filter(u=> u.isDeleted && u.userId !== this.taskPreview?.assignedTo).map(h=> h.userId);
        if (this.usersInProject) {
            if (this.taskPreview!.accessType === 'OnlyMe') {
                return this.usersInProject.filter(x=> x.userId === this.userProfileStore.userProfile.userId && !hiddenUsers.includes(x.userId));
            }
            if (this.taskPreview!.accessType === 'LimitedAccess') {
                return this.usersInProject.filter(x=> 
                    (x.userId === this.userProfileStore.userProfile.userId || 
                    this.taskPreview!.sharedWith?.includes(x.userId) ||
                   x.userId === this.taskPreview!.createdBy && 
                   x.userId !== this.userProfileStore.userProfile.userId) &&
                   !hiddenUsers.includes(x.userId)
                
                );
            }
        }
        return this.usersInProject.filter(u => !hiddenUsers.includes(u.userId)) || [];
    }

    get selectedAttachmentObjects() {
        return this.selectedAttachments ? this.taskAttachments.filter(p => this.selectedAttachments.includes(p.id)) : [];
    }

    get taskStatusName() {
        const taskStatus = this.taskStatusesForType.find(t=> t.id === this.taskPreview?.status);
        return taskStatus?.name;
    }

    get subtaskStatusesForType() {
        return this.taskType?.distinctSubtaskStatuses ? this.taskStatusesForType.filter(t=> t.isSubtaskStatus) : this.taskStatusesForType;
    }

    get taskTypes() {
        return this.tasksRootStore.taskTypes.filter(t=> t.projectId === this.taskPreview?.projectId);
    }

    get taskType() {
        return this.taskTypes.find(t=> t.id === this.taskPreview?.taskType);
    }

    get taskTypeName() {
        const taskType = this.taskTypes.find(t=> t.id === this.taskPreview?.taskType);
        return taskType?.name;
    }

    get subTasks() {
        return this.tasksRootStore.tasks.filter(t=> !!t.taskId);
    }

    get taskTabs() {
        return this.tabsStore.taskTabs;
    }

    get isPinned() {
        return this.taskTabs.map(t=> t.id).includes(this.taskPreview?.id || '');
    }

    get isFollowingCurrentTask() {
        if (this.taskPreview) {
            return this.taskPreview.followers ? this.taskPreview.followers.includes(this.currentUserId) : false;
        }

        return false;
    }

    get taskPreviewDisplayName() {
        if (this.taskPreview) {
            return this.taskPreview.alphaId ? `${this.taskPreview.alphaId} - ${this.taskPreview.name}` : this.taskPreview.name;
        }

        return null;
    }

    get taskPreviewTypes() {
        return this.taskTypes.filter(x=> x.projectId === this.taskPreview!.projectId);
    }

    get tasksLoading() {
        return this.tasksRootStore.tasksLoading;
    }

    get taskTypeChangeDisabled() {
        return this.taskFormLocked || !this.taskTypes || this.taskTypes.length === 0 || !this.taskPreview?.isManuallyCreated || this.taskPreview?.isSubtask;
    }

    get seletedActionModel() {
        return this.availableActions.find(x => x.id === this.selectedAction);
    }

    
    get filteredAvailableTaskActions() {
        return this.availableActions
            .filter(x => !x.isAttachment)
            .map(x => ({action: x, canExecute: ActionHelper.canExecute(x, this.taskPreview!, undefined, this.actionEvents)}))
            .sort((a, b) => a.action.position -   b.action.position);
    }

    removeSubscriptions() {
        for(const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    getAttachmentName(packageId: string) {
        return this.taskAttachments.find(x => x.id === packageId)?.fileName;
    }

    getAvailableAttachmentActions(pkg: PackagePreviewModel) {
        return this.availableActions
            .filter(x => x.isAttachment)
            .map(x => ({action: x, canExecute: ActionHelper.canExecute(x, this.taskPreview!, pkg, this.actionEvents)}));
    }

    setPageIsLoading(isLoading: boolean) {
        this.tasksRootStore.setPageIsLoading(isLoading);
    }

    setIsActionLoading(isLoading: boolean) {
        this.isActionLoading = isLoading;
    }

    setScrollToSections(scrollToSections: ScrollToSections | undefined) {
        this.scrollToSections = scrollToSections;
    }

    setSectionActiveKey(key: string, activate: boolean) {
        if (activate) {
            this.activeSectionKeys.push(key);
        } else {
            const index = this.activeSectionKeys.indexOf(key);
            this.activeSectionKeys.splice(index, 1);
        }
    }

    async togglePreview(taskToToggle: TaskListModel | undefined, scrollToSections?: ScrollToSections) {
        const taskId = taskToToggle?.id;

        runInAction(() => {
            this.selectedAttachments = [];
            this.taskAttachments = [];
            this.taskStatusesForType = [];
            this.editingTitle = false;
            this.taskActivities = [];
            this.availableActions = [];
            this.taskLinks = [];
            this.taskPrefix = undefined;
        });

        if (taskId) {
            const isSubtask = !!taskToToggle!.taskId;
            try {
                if (!isSubtask && !taskToToggle.isViewed) {
                    this.updateField(taskToToggle.id, 'IsViewed', true);
                }
                this.activeSectionKeys = [];
                runInAction(() => this.taskPreview = { ...taskToToggle, isSubtask: isSubtask }); // Create a clone of object which might be edited (?)
                this.setNewDescription(this.taskPreview!.description);
                
                const promises = [
                    this.loadTaskStatusesForType(taskToToggle.taskType),
                    this.loadTaskPrefix(),
                    this.loadPreviewTaskAttachments(),
                    this.loadTaskActivities(),
                    this.loadAvailableActions(),
                    this.loadActionEvents(),
                    this.tasksRootStore.loadMetadata(this.taskPreview!.projectId),
                    this.loadTaskLinks(),
                    this.tasksRootStore.loadUsersForProject(taskToToggle.projectId)
                ];
                await Promise.all(promises);
                runInAction(() => this.setActiveSections());
                setTimeout(() => this.setScrollToSections(scrollToSections), 200);
            } catch (err) {
                this.errorStore.addBasicError(err);
                runInAction(() => {
                    this.setAttachmentsLoading(false);
                });
            }
        } else {
            this.taskPreview = undefined;
        }
    }

    async hanldeAssignToChange(value: unknown) {
        const updateTasksCallback = async(tasks: TaskListModel[]) => {
            const {parentTask} = this.taskPreview!;
            if (parentTask) {
                const index = tasks.findIndex(t=> t.id === parentTask.taskId);
                if (index) {
                    const subtaskIndex = tasks[index].subTasksMeta!.findIndex(s=> s.subtaskId === this.taskPreview!.id);
                    tasks[index].subTasksMeta![subtaskIndex].assignedTo = value as string; 
                }
            }
            return Promise.resolve(tasks);
        };
        await this.updateField(this.taskPreview!.id, 'AssignedTo', value, undefined, updateTasksCallback);
    }

    setEmailTaskToUserDialogVisible(visible: boolean) {
        this.emailTaskToUserDialogVisible = visible;
    }

    setNewDescription(description: string | undefined) {
        this.newDescription = description;
    }

    setTaskDrawerIsResizing(resize: boolean) {
        this.taskDrawerIsResizing = resize;
    }

    setTaskDrawerWidth(width: number) {
        this.taskDrawerWidth = width;
    }


    handleAssignedToChange(model: AssignedToChangesModel) {
        if (this.taskPreview?.id === model.taskId) {
            this.taskPreview.assignedTo = model.assignedTo;
        }
    }

    handleFollowersChange(model: FollowersChangeModel) {
        if (this.taskPreview?.id === model.taskId) {
            this.taskPreview.followers = model.followers;
        }
    }

    handlePinClick(softRemove: boolean) {
        if(this.isPinned) {
            this.unpinTask(this.taskPreview!.id, softRemove);
        } else {
            this.pinTask(this.taskPreview!);
        }
    }

    pinTask(task: TaskListModel) {
        const taskDisplayName = task.alphaId ? `${task.alphaId} - ${task.name}` : task.name;
        this.tabsStore.addTabForPinnedTask({
            id: task.id,
            title: taskDisplayName,
            type: 'task',
            isSelected: false,
            isFullPreview: false,
            isSubtask: task.taskId ? true : false,
            parentTitle: task.taskId ? this.getParentTaskTitle(task.parentTask!) : undefined,
            metadata: {}
        });
        this.tasksService.pinTask(task.id);
    }

    async unpinTask(taskId: string, softRemove: boolean = false) {
        this.tabsStore.removeTabForTask(taskId, softRemove);
        await this.tasksService.unpinTask(taskId);
    }

    async getAttachmentText(packageId: string) {       
        const response = await this.tasksService.readTextTaskAttachment(packageId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return response.mapErr((err: any) => this.errorStore.addError(err.data)).unwrapOr('');
    }
    
    setPreviewAttachment(attachmentId: string | undefined) {
        if (!attachmentId) {
            this.previewAttachment = undefined;  
        }

        if (this.taskAttachments) {
            this.previewAttachment = this.taskAttachments.find(a => a.id === attachmentId);
        }
    }

    
    getRelatedTask() {
        return this.taskPreview?.metadata?.internalReference;
    }

    toggleAttachmentSelection(attachmentId: string) {
        if (this.selectedAttachments.find(id => id === attachmentId)) {
            this.selectedAttachments = this.selectedAttachments.filter(id => id !== attachmentId);
        } else {
            this.selectedAttachments.push(attachmentId);
        }
    }

    parseEmailMetadata(task: TaskModel): EmailMetadata | undefined {
        const containsEmailMetadata: boolean = !!task && !!task.metadata && (
            !!task.metadata.email_textbody
            || !!task.metadata.email_from
            || !!task.metadata.email_to
            || !!task.metadata.email_subject
            || !!task.metadata.email_date);

        if (task && containsEmailMetadata) {
            let emailMetadata: EmailMetadata = {
                body: task.metadata?.email_textbody ? task.metadata.email_textbody[0] : undefined,
                from: task.metadata?.email_from ? task.metadata.email_from[0] : undefined,
                to: task.metadata?.email_to || [],
                subject: task.metadata?.email_subject ? task.metadata.email_subject[0] : undefined,
                date: task.metadata?.email_date ? task.metadata.email_date[0] : undefined,
            };

            return emailMetadata;
        } else {
            return undefined;
        }
    } 

    async handleAssignToSave(value: string) {
        await this.updateField(this.taskPreview!.id, 'AssignedTo', value);
        await this.tasksRootStore.loadTasks();
    }

    async handleTypeChange (newTypeId: string) {
        await this.loadTaskStatusesForType(newTypeId);
        if (this.taskStatusesForType && this.taskStatusesForType.length > 0) {
            const {distinctSubtaskStatuses} = this.taskType!;
            const status = this.taskStatusesForType.find(s=> s.isDefault &&  (distinctSubtaskStatuses ? this.taskPreview!.isSubtask == s.isSubtaskStatus : true));
            await this.updateField(this.taskPreview!.id, 'Status', status!.id);
            this.loadAvailableActions();
            if (this.taskAttachments.length > 0) {
                this.loadTaskAttachments();
            }
        }        
    }

    async updateField( 
        taskId: string, 
        fieldName: string,
        value: unknown, 
        callback: Function | undefined = undefined, 
        updateTasksCallback?: (tasks: TaskListModel[]) => Promise<TaskListModel[]>,
        reloadTasks?: boolean
    ) {
        await this.tasksRootStore.updateField(taskId, fieldName, value, callback, updateTasksCallback, reloadTasks);
        if (this.taskPreview && this.taskPreview.id === taskId) {
            const localFieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
            this.taskPreview[localFieldName] = value;
        }
        await this.loadTaskActivities();
    }

    async loadPreviewTaskAttachments(silent: boolean = false) {
        if (!this.taskPreview) {
            return; 
        }

        this.setAttachmentsLoading(true && !silent);
        const taskAttachments = await this.tasksService.getPackageSet(this.taskPreview.packageSetId);
        runInAction(() => {
            this.taskAttachments = taskAttachments;
            this.syncAttachments(this.taskPreview!.isSubtask);
            this.setAttachmentsLoading(false);
        });
    }

    setAttachmentsLoading(isLoading: boolean) {
        this.attachmentsLoading = isLoading;
    }

    async handlePrivacyChange(task: TaskListModel, value: TaskPrivacyType, sharedWith: string[]) {  
        this.tasksRootStore.handlePrivacyChange(task, value, sharedWith);
    }

    async saveNewDescription() {
        if (this.taskPreview) {
            await this.updateField(this.taskPreview!.id, 'Description', this.newDescription);
            runInAction(() => {
                this.taskPreview!.description = this.newDescription;
            });
        }
    }

    async openParentTask() {
        const taskId = this.taskPreview!.parentTask!.taskId;
        const task  = this.tasks.find(t=> t.id === taskId);
        if (task) {
            this.togglePreview(task);
        } else {
            this.setTasksIsLoading(true);
            const resp = await this.getTaskById(taskId);
            resp.map(taskById => {
                this.setTasksIsLoading(false);
                this.togglePreview(taskById!);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                this.errorStore.addError(err.data);
                this.setTasksIsLoading(false);
            });
        }
    }

    
    async saveSubTask(name: string, taskId: string) {
        this.setSubtaskLoadingInProgress(true);
        await this.tasksService.saveSubTask(name, taskId);
        await this.tasksRootStore.loadTasks();
        const task = this.tasks.find(t=> t.id === this.taskPreview!.id);
        this.togglePreview(task);
        this.setSubtaskLoadingInProgress(false);

    }

    setSubtaskLoadingInProgress(isLoading: boolean) {
        this.isSubtaskLoadingInProgress = isLoading;
    }

    setEditingTitle(editing: boolean, title: string) {
        this.editingTitle = editing;
        this.newTitle = title;
    }

    setNewTitle(title: string) {
        this.newTitle = title;
    }

    hasAccessToMetadata(taskTypes: string[] | null) {
        const { taskType} = this.taskPreview!;
        return taskTypes?.length ? taskTypes.includes(taskType) : true;
    }

    async appendFilesToPackageSet(packageSetId: string, files: RcFile[], taskId: string) {
        try {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('files', file);
            });

            formData.append('taskId', taskId);

            const resp = await this.tasksService.appendAttachments(formData);
            await resp.asyncMap(async () => {
                await this.loadPreviewTaskAttachments(true);
                this.attachmentChangesSubject.next({
                    packageSetId: packageSetId,
                    action: 'add',
                    attachmentId: undefined
                }); 
                await this.loadTaskActivities();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => this.errorStore.addError(err.data));
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async getUserProfilePicture(userId: string) {
        this.tasksRootStore.getUserProfilePicture(userId);
    }

    setAssignedUserInPreview(userId: string, taskIds: string[]) {
        if (this.taskPreview  && taskIds.includes(this.taskPreview.id)) {
            this.taskPreview.assignedTo = userId;
        }
    }

    async removeSelectedAttachments(id?: string) {
        if (id) {
            await this.removeAttachment(this.taskPreview!.packageSetId, id);
            const index = this.selectedAttachments.findIndex(x=> x === id);
            if (index > - 1) {
                this.selectedAttachments.splice(index, 1);
            }
            return;
        }
        if (!this.selectedAttachments || !this.selectedAttachments.length || !this.taskPreview) {
            return; 
        }

        this.selectedAttachments.forEach(async (attachment) => {
            await this.removeAttachment(this.taskPreview!.packageSetId, attachment);
        });

        runInAction(() => {
            this.selectedAttachments = [];
        });
    }

    async removeAttachment(packageSetId: string, attachmentId: string) {
        if (!this.taskPreview) {
            return; 
        }

        const resp = await this.tasksService.removeAttachment(this.taskPreview.id, attachmentId);
        await resp.asyncMap(async () => {
            runInAction(() => {
                this.taskAttachments = this.taskAttachments.filter(a => a.id !== attachmentId);
                this.attachmentChangesSubject.next({
                    packageSetId: packageSetId,
                    attachmentId: attachmentId,
                    action: 'remove'
                });
                this.syncAttachments(this.taskPreview!.isSubtask);
            });
            await this.loadTaskActivities();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }


    async loadTaskAttachments(silent: boolean = false) {
        this.setAttachmentsLoading(true && !silent);
        const previewAttachments = await this.tasksService.getPackageSet(this.taskPreview!.packageSetId);
        runInAction(() => {
            this.taskAttachments = previewAttachments;
            this.setAttachmentsLoading(false);
        });
    }

    getStatusDisplayName(status: string) {
        const taskStatus = this.taskStatusesForType.find(s => s.id === status);

        if (taskStatus) {
            return taskStatus.name;
        }

        return '';
    }

    getStatusById(statusId: string) {
        return this.taskStatusesForType.find(s => s.id === statusId);
    }

    copyTaskUrl() {
        Utils.copyTaskUrlToClipboard(this.taskPreview!.id);
    }

    async loadTaskStatusesForType(typeId: string) {
        if (this.taskPreview && typeId ) {
            runInAction(() => {
                this.taskStatusesLoading = true;
            });
            
            const response = await this.tasksService.getTaskStatuses(typeId);
            response.map((result) => {
                runInAction(() => {    
                    this.taskStatusesForType = result;
                });
            }).mapErr((err) => this.errorStore.addError(err));

            runInAction(() => {
                this.taskStatusesLoading = false;
            });
        }
    }

    getTaskById = (id: string) => {
        return this.tasksService.getTaskById(id, null);
    };


    getTaskStatusDisplay(taskStatus: TaskStatus) {
        return taskStatus.isEnabled && !!this.taskStatusIdsFiltered.find(s => s === taskStatus.id) ? 'block' : 'none';
    }

    getQueryButtonName() {
        return this.taskPreview!.statusName === 'Queried' || this.taskTypeName === 'Settlement' ? 'Reject': 'Query';
    }

    expandTask(task: TaskListModel) {
        if (!task.isViewed) {
            this.updateField(task.id, 'IsViewed', true);
        }
        this.togglePreview(task);
    }

    async handleAttachmentDownload(packageId: string | undefined = undefined) {
        try {
            if (packageId) {
                const attachment = this.taskAttachments.find(a => a.id === packageId);
                if (attachment) {
                    await this.downloadAttachment([attachment]);
                }
            } else {
                const attachments = this.taskAttachments.filter(a => this.selectedAttachments.indexOf(a.id) !== -1);
                await this.downloadAttachment(attachments);
                    
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async toggleTaskFollowing(taskId: string) {
        const response = await this.tasksService.toggleTaskFollowing(taskId);
        response.map((newFollowers: string[]) => {
            this.followersChangesSubject.next({taskId, followers: newFollowers});
 
            if (this.taskPreview && this.taskPreview.id === taskId) {
                runInAction(() => {
                    this.taskPreview!.followers = newFollowers;
                });
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async emailTaskToUsers(taskId: string, emailAddresses: string[]) {
        runInAction(() => {
            this.sendingEmailToUsers = true;
        });
        const response = await this.tasksService.emailTaskToUsers(taskId, emailAddresses);
        response.map(() => {
            message.success('Email successfully sent');
            runInAction(() => {
                this.emailTaskToUserDialogVisible = false;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
        runInAction(() => {
            this.sendingEmailToUsers = false;
        });
    }

    async handleStatusChangeSave(value: string) {
        await this.updateField(this.taskPreview!.id, 'Status', value);
        await this.tasksRootStore.loadTasks();
    }

    
    async deleteTask() {
        const taskId = this.taskPreview!.id;
        try {
            await this.updateField(taskId, 'Deleted', true);
            runInAction(() => {
                const parentTaskId = this.tasks.find(t=> t.id === taskId)!.taskId;
                if (parentTaskId) {
                    const parentTask = this.tasks.find(p=> p.id === parentTaskId);
                    if (parentTask) {
                        parentTask.subTasksMeta = parentTask.subTasksMeta?.filter(s=> s.subtaskId !== taskId);
                    }
                }
                const subtaskIds = this.taskPreview!.subTasksMeta?.map(s=> s.subtaskId) ?? [];
                this.tasksRootStore.setTasksList(this.tasks.filter(t => ![...subtaskIds, taskId].includes(t.id)));
                this.tasksRootStore.unSelectRow(taskId);
                this.togglePreview(undefined);
                this.tabsStore.removeTabForTask(taskId, false);
            });
        }  catch (err) {
            this.errorStore.addBasicError(err);
        }
    }


    getTaskTitle(task: TaskModel) {
        return task.alphaId ? `${task.alphaId.prefix}-${task.alphaId.index} - ${task.name}` : task.name;
    }

    getParentTaskTitle(meta: ParentTaskMeta) {
        return meta.alphaId ? `${meta.alphaId.prefix}-${meta.alphaId.index} - ${meta.name}` : meta.name;
    }

    setQueriedStatus() {
        const statusId = this.taskStatusesForType.find(t=> t.name === 'Queried')!.id;
        this.taskPreview!.status = statusId;
        this.taskPreview!.statusName = 'Queried';
    }

    setSelectedAction(actionId: string | undefined, packageId?: string) {
        this.selectedAction = actionId;
        this.selectedPackageForAction = packageId;
    }

    async executeAction(taskId: string, actionId: string, payload: unknown, isClient: boolean, packageId?: string) {
        if (isClient) {
            try {
                const result = await this.tasksService.prepareClientAction(taskId, actionId, payload, packageId);
                result.map(data => {
                    this.actionRunner.executeAction(data);
                });
            } catch (err) {
                this.errorStore.addBasicError(err);
            }
        } else {
            const resp = await this.tasksService.executeAction(taskId, actionId, payload, packageId);
            
            if (resp.isOk()) {
                await this.loadTaskActivities();
            } else {
                resp.mapErr((err) => this.errorStore.addError(err.data));
            }
        }
    }

    async updateMetadataField(taskId: string, fieldName: string, value: unknown) {
        const response = await this.tasksService.updateMetadataField(taskId, fieldName, value);
        if (response.isOk()) {
            message.success('Metadata field successfully updated');
            runInAction(() => {
                this.taskPreview!.metadata![fieldName] = value;
            });
        } else {
            message.error('There was a problem updating metadata field.');
        }
        return response;
    }

    setSelectedLink(linkId: string | undefined | null) {
        this.selectedLink = linkId;
    }

    setInitialLookup(taskLink: TaskLink) {
        const targetTask = this.taskPreview?.id === taskLink.taskId ?
            { id: taskLink.linkedTaskId, identifier: taskLink.linkedTaskIdentifier, title: taskLink.linkedTaskTitle } :
            { id: taskLink.taskId, identifier: taskLink.taskIdentifier, title: taskLink.taskTitle };

        this.taskLookups = [{
            alphaId: targetTask.identifier,
            id: targetTask.id,
            isSubtask: false,
            name: targetTask.title
        }];
    }

    async lookupTasks(searchTerm: string) {
        if (!searchTerm) {
            runInAction(() => {
                this.taskLookups = [];
            });

            return;
        }
        
        try {
            const result = await this.tasksService.lookupTasks(this.taskPreview!.projectId, searchTerm, this.taskPreview?.id);
            result.map((tasks: TaskLookupItem[]) => {
                runInAction(() => {
                    this.taskLookups = tasks;
                });
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    clearTaskLookups() {
        this.taskLookups = [];
    }

    async createTaskLink(formData: FormData) {
        const resp = await this.tasksService.createTaskLink(formData);
        if(resp.isOk()){
            runInAction(()=>{
                this.loadTaskLinks();
                this.loadTaskActivities();
            });
        } else {
            console.error(resp.error);
        }
    }

    async deleteTaskLink(linkId: string) {
        const resp = await this.tasksService.deleteTaskLink(linkId);
        if (resp.isOk()) {
            const index = this.taskLinks.findIndex(x=>x.id === linkId);
            if (index > -1) {
                runInAction(() => {
                    const taskLinks = this.taskLinks.slice();
                    taskLinks.splice(index, 1);
                    this.taskLinks = taskLinks;
                    this.loadTaskActivities();
                });
            }
        } else {
            console.error(resp.error);
        }
    }

    setPinnedTasks(pinnedTasks: PinnedTask[]) {
        for(let pinnedTask of pinnedTasks) {
            const taskDisplayName = pinnedTask.alphaId ? `${pinnedTask.alphaId.prefix}-${pinnedTask.alphaId.index} - ${pinnedTask.name}` : pinnedTask.name;
            const tab = {
                id: pinnedTask.id,
                title: taskDisplayName,
                type: 'task',
                isSelected: false,
                isFullPreview: false,
                isSubtask: pinnedTask.parentTask ? true : false,
                parentTitle: pinnedTask.parentTask ? this.getTaskTitle(pinnedTask.parentTask!) : undefined,
                metadata: {task: pinnedTask}
            } as TabModel;
            this.tabsStore.addTabForPinnedTask(tab);
        }
    }

    async loadTaskActivities() {
        if (!this.taskPreview) {
            return;
        }
        try {
            this.taskActivitiesLoading = true;
            var result = await this.tasksService.getTaskActivities(this.taskPreview!.id);
            runInAction(() => {
                this.taskActivities = result;
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.taskActivitiesLoading = false;
            });
        }
    }

    async loadActionEvents() {
        if (!this.taskPreview) {
            return;
        }

        try {
            runInAction(() => this.actionEventsLoading = true);
            var result = await this.tasksService.getActionEvents(this.taskPreview.id);
            result.map((events: ActionEvent[]) => {
                runInAction(() => this.actionEvents = events.sort((a, b) => Utils.safeStringLocaleCompare(a.triggeredOn, b.triggeredOn) * -1));
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => this.errorStore.addError(err.data));
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => this.actionEventsLoading = false);
        }
    }

    async acceptMessage(taskId: string) {
        const resp = await this.tasksService.acceptMessage(taskId);
        if (resp.isOk()) {
            message.success('Message has been successfully put in the processing queue');
        } else {
            message.error('There was a problem processing the message');
        }
    }

    async queryMessage(taskId: string, queryData: QueryRequestData) {
        const resp = await this.tasksService.queryMessage(taskId, queryData);
        const statusId = this.taskStatusesForType.find(t=> t.name === 'Queried')!.id;
        if (resp.isOk()) {
            this.updateField(taskId, 'Status', statusId);
        }
        return resp;
    }

    async rejectMessage(taskId: string) {
        const resp = await this.tasksService.rejectMessage(taskId);
        if (resp.isOk()) {
            message.success('Message has been successfully rejected');
            const statusId = this.taskStatusesForType.find(t=> t.name === 'Queried')!.id;
            this.updateField(taskId, 'Status', statusId);
        } else {
            message.error('There was a problem rejecting the message');
        }
        return resp;
    }

    
    setTasksIsLoading(isLoading: boolean) {
        this.tasksRootStore.setTasksIsLoading(isLoading);
    }

    loadAvailableActions() {
        const status = this.tasksRootStore.taskStatuses.find(x => x.id === this.taskPreview!.status);
        const available = this.tasksRootStore.actionDefinitions.filter(x => status?.allowedActions?.includes(x.id)) || [];
        
        runInAction(() => {
            this.availableActions = available;
        }); 
    }

    private async downloadAttachment(attachments: PackagePreviewModel[]) {
        try {
            await this.tasksService.handleDownload(attachments.map(a => a.id));
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    private async handleAttachmentUpdates(attachmentUpdate: AttachmentUpdateModel) {
        if (attachmentUpdate.packageSetId === this.taskPreview!.packageSetId) {
            switch (attachmentUpdate.action) {
            case 'add':
                await this.loadTaskAttachments();
                break;
            case 'remove':
                runInAction(() => {
                    this.taskAttachments = this.taskAttachments.filter(t => t.id !== attachmentUpdate.attachmentId);
                });
                break;
            default:
                return;
            }
            this.loadTaskActivities();
        }
    }

    private async loadTaskLinks() {
        this.taskLinksLoading = true;
        const resp = await this.tasksService.getTaskLinks(this.taskPreview!.id);
        resp.map((taskLinks:  TaskLink[]) => {
            runInAction(() => {
                this.taskLinks = taskLinks;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
        runInAction(() => this.taskLinksLoading = false);
    }

    private async loadTaskPrefix() {
        try {
            runInAction(() => {
                this.taskPrefixLoading = true;
            });
            var result = await this.tasksService.getTaskPrefixForProject(this.taskPreview!.projectId);
            runInAction(() => {
                this.taskPrefix = result ? result.name : undefined;
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        } finally {
            runInAction(() => {
                this.taskPrefixLoading = false;
            });
        }
    }

    private async updateTaskField(task: TaskModel) {
        if (this.taskPreview?.id === task.id) {
            const status = this.taskStatusesForType.find(t=> t.id === task.id);
            this.taskPreview.status = task.status;
            this.taskPreview.statusName = status!.name;
        }
    }

    
    private syncAttachments(isSubtask: boolean, taskId?: string, attachments?: PackagePreviewModel[]) {
        const id = taskId || this.taskPreview!.id;
        const index = this.tasks.findIndex(x=> x.id === id);
        if (index < 0) {
            return;
        }
        const tasks =  this.tasks.slice();
        tasks[index].attachmentsCount = attachments?.length === undefined  ? this.taskAttachments.length: attachments.length;
        this.tasksRootStore.setTasksList(tasks);

        if (attachments) {
            this.taskAttachments = attachments;
        }
    }

    private updateAttachments(packageChanges: PackageChanges) {
        const attachment = this.taskAttachments.find(a=> a.id === packageChanges.id);
        if (attachment) {
            attachment.state = packageChanges.state;
        }
    }

    private handleActionCompleted(result: ActionCompletedResult) {
        if (result.event.userId === this.userProfileStore.currentUserId) {
            if (result.event.status === 'Failed') {
                message.error(result.message, 10);
            } else {
                message.success(result.message, 10);
            }
            this.loadTaskActivities();
            this.loadActionEvents();
            if (result.event.packageId) {
                this.loadTaskAttachments();
            }
        }
    }

    private handleActionTriggered(result: ActionTriggeredResult) {
        if (result.event.userId === this.userProfileStore.currentUserId) {
            this.actionEvents.unshift(result.event);
        }
    }

    private setActiveSections() {
        if (this.taskLinks.length) {
            this.activeSectionKeys.push('linked-tasks');
        }
        if (this.taskAttachments.length) {
            this.activeSectionKeys.push('attachments');
        }
        if (this.taskPreview!.description && this.taskPreview!.description !== '<p><br></p>') {
            this.activeSectionKeys.push('description');
        }
        if (Object.values(this.taskPreview!.metadata ?? {}).filter(v=> v).length) {
            this.activeSectionKeys.push('metadata');
        }
        if (this.taskPreview!.errors.length) {
            this.activeSectionKeys.push('error');
        }
    }
}