import { Checkbox, Form, Input, InputNumber, Select, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import MetadataDefinitionStore from '../stores/MetadataDefinitionStore';
import { MetadataFieldType } from '../types/Metadata';
import { FormInstance } from 'antd/es/form';
import { ONLY_DATE, FULL_DATE, ONLY_TIME } from '../../common/misc/Utils';
import { InfoCircleOutlined } from '@ant-design/icons';
const FormItem = Form.Item;

type Props = {
    store: MetadataDefinitionStore;
    form: FormInstance
};

const MetadataDefinitionEditForm: React.FC<Props> = ({ store, form }) => {
    
    const mode = store.editDialogMode;

    React.useEffect(() => {
        if (store.selectedDefinition) {
            const metadata = store.selectedDefinition;
            if (metadata.format && metadata.fieldType !== 'DateTime') {
                JSON.parse(metadata.format);
                form.setFieldsValue({...store.selectedDefinition, ...JSON.parse(metadata.format)});
            } else {
                form.setFieldsValue(store.selectedDefinition);
            }
        }
    }, [store.editDefinitionDialogVisible, store.selectedDefinition, form]);

    const handleSubmit = () => {
        form.validateFields().then((formData) => {    
            switch (store.selectedDefinition?.fieldType) {
            case 'Text':
                formData.format = JSON.stringify({prefix: formData.prefix ?? null, suffix: formData.suffix ?? null});
                break;
            case 'Boolean':
                formData.format = JSON.stringify({trueVal: formData.trueVal ?? null, falseVal: formData.falseVal ?? null});
                break;
            case 'Number':
                formData.format = JSON.stringify({precision: formData.precision, separator: formData.separator});
                break;
            default:
                break;
            }     
            if (mode === 'create') {
                store.createMetadataDefinition(formData);
            } else {
                store.updateMetadataDefinition(formData.title, formData.description, formData.format, formData.isVisible, formData.isEditable, formData.taskTypes);
            }
            form.resetFields();
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleTypeChange = (val: MetadataFieldType) => {
        if (val === 'DateTime') {
            form.setFieldsValue({format: ONLY_DATE});
        }
        store.handleTypeChange(val);
    };

    const getFormatTypeRenderer = () => {
        switch (store.selectedDefinition?.fieldType) {
        case 'Text':
            return (
                <Space>
                    <FormItem name='prefix'>
                        <Input placeholder='prefix' style={{width: 100}}/>
                    </FormItem>
                    <div style={{marginBottom: 24}}>{'{value}'}</div>
                    <FormItem name='suffix'>
                        <Input placeholder='suffix' style={{width: 100}}/>
                    </FormItem>
                </Space>);
        case 'DateTime':
            return  (
                <FormItem name='format'>
                    <Select options={[{label: 'Full date', value: FULL_DATE}, {label: 'Short date', value: ONLY_DATE}, {label: 'Only time', value: ONLY_TIME}]}/>
                </FormItem>);
        case 'Boolean': 
            return (
                <Space>
                    <FormItem name='trueVal'>
                        <Input style={{width: 100}} placeholder='true'/>
                    </FormItem>
                    <div style={{marginBottom: 24}}>{'/'}</div>
                    <FormItem name='falseVal'>
                        <Input style={{width: 100}} placeholder='false'/>
                    </FormItem>
                </Space>);  
        case 'Number':
            return(
                <Space style={{marginLeft: 20}}>
                    <FormItem name='precision'  label={<span style={{fontSize: 12}}>Precision</span>}>
                        <InputNumber max={10} min={0} style={{width: 100}}/>
                    </FormItem>
                    <FormItem name='separator' valuePropName="checked" label={<span style={{fontSize: 12}}>Thousand separator</span>}>
                        <Checkbox/>
                    </FormItem>
                </Space>);  
        default:
            return <></>;
        }
    };
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadataNameValidator = (rule: any, value: any, callback: any) => { 
        let metadataToValidate = store!.metadataDefinitions.map(m => m.name.toLowerCase());
        if (value && metadataToValidate.includes(value.toLowerCase()) && store.selectedDefinition?.name !== value) {
            callback('Metadata with the same name already exists');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadataTitleValidator = (rule: any, value: any, callback: any) => { 
        let metadataToValidate = store!.metadataDefinitions.map(m => m.title.toLowerCase());
        if (value && metadataToValidate.includes(value.toLowerCase()) && store.selectedDefinition?.title !== value) {
            callback('Metadata with the same title already exists');
        } else {
            callback();
        }
    };

    return (
        <div className="dialog-section">
            <Form form={form} onFinish={handleSubmit} className="inputs-container" id="edit-metadata-definition-form" layout='vertical'>
                <FormItem name="projectId" hidden/>
                <FormItem
                    name="name"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Name</span>}
                    rules={[
                        { required: true, message: 'Name is required' },
                        { validator: metadataNameValidator }
                    ]}
                >
                    <Input disabled={mode === 'edit'} />
                </FormItem>
                <FormItem
                    name="fieldType"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Field type</span>}
                >
                    <Select
                        suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                        disabled={mode === 'edit'}
                        onChange={handleTypeChange}
                    >
                        <Select.Option value="Text">Text</Select.Option>
                        <Select.Option value="Number">Number</Select.Option>
                        <Select.Option value="Boolean">Boolean</Select.Option>
                        <Select.Option value="DateTime">Date</Select.Option>
                    </Select>
                </FormItem>
                <FormItem
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Format</span>}
                >

                    {getFormatTypeRenderer()}
                </FormItem>
                <FormItem
                    name="title"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[
                        { required: true, message: 'Title is required' },
                        { validator: metadataTitleValidator}
                    ]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="taskTypes"
                    colon={false}
                    className="dialog-field"
                    label={
                        <div>
                            <span className="dialog-field-label">Task types</span>
                            <Tooltip title='If no task type is selected, the metadata will be displayed for all types of tasks'>
                                <InfoCircleOutlined style={{marginLeft: 10}}/>
                            </Tooltip>
                        </div>
                    }
                >
                    <Select 
                        mode='multiple'
                        options={store.taskTypes.map(t=> ({value: t.id, label: t.name}))}
                    />
                </FormItem>
                <FormItem
                    name="description"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Description</span>}
                >
                    <Input />
                </FormItem>
                <Form.Item name='isVisible' valuePropName="checked">
                    <Checkbox>
                        Is visible
                        <Tooltip title='If checked the regular user will see metadata, otherwise only admin will see it'>
                            <InfoCircleOutlined className='checkbox-info-tooltip'/>
                        </Tooltip>
                    </Checkbox>
                </Form.Item>
                <Form.Item name='isEditable' valuePropName="checked">
                    <Checkbox>
                        Is editable
                        <Tooltip title='If checked the regular user will be able to edit the value of this metadata field, otherwise only admin will be able to do that.'>
                            <InfoCircleOutlined className='checkbox-info-tooltip'/>
                        </Tooltip>
                    </Checkbox>
                </Form.Item>
            </Form>
        </div>
    );
};

export default observer(MetadataDefinitionEditForm);