import * as React from 'react';
import { observer } from 'mobx-react-lite';
import DocumentViewVisualStore from '../stores/DocumentViewVisualStore';
import { Spreadsheet, SpreadsheetHandle } from '@progress/kendo-react-spreadsheet';

type Props = {
    store: DocumentViewVisualStore
};

const ExcelViewer: React.FC<Props> = ({store}) => {
    const spreadsheet = React.useRef<SpreadsheetHandle>(null);

    React.useEffect(() => {
        if (!spreadsheet || !store.fileBlob) {
            return;
        }

        spreadsheet.current!.fromFile(store.fileBlob);
    }, [spreadsheet, store.fileBlob]);

    return (
        <Spreadsheet
            style={{
                width: '100%',
                height: 680
            }}
            toolbar={false}
            ref={spreadsheet}
        />
    );
};

export default observer(ExcelViewer);
