import { DashboardWidgetType } from './DashboardWidget';

export type DashboardWidgetMetaBase = {
    id: string;
    name: string;
    projectId: string;
    widgetType: DashboardWidgetType;
    isDefaultWidget?: boolean
};

export type DashboardWidgetMeta = {
    outputField: WidgetOutputField[];
    filters: WidgetFilter[]
} & DashboardWidgetMetaBase;

export type WidgetOutputField = {
    name: string; 
    type: 'General' | 'Metadata'
};

export type DashboardGroupedWidgetMeta = {
    widgets: string[]
} & DashboardWidgetMetaBase;

export class WidgetFilter {
    entityReference: string;
    fieldType: 'General' | 'Metadata';
    operator: FilterOperator;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[];
    extraFilters: ExtraFilters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(entityReference: string, fieldType: 'General' | 'Metadata', operator: FilterOperator, values: any[], extraFilters: ExtraFilters) {
        this.entityReference = entityReference;
        this.fieldType = fieldType;
        this.operator = operator;
        this.value = values;
        this.extraFilters = extraFilters;
    }

}

type ExtraFilters = {
    extraDateFilter: ExtraDateFilter
};

export type ExtraDateFilter = {
    periodValue: 'D'| 'W' | 'M' | 'Y';
    periodCount: number
};

export enum FilterOperator {
    Equal = '=',
    NotEqual = '\u{2260}',
    IsGreaterThen = '>',
    IsLessThen = '<'
}

export interface DashboardWidgetsResult {
    getDashboardWidgetsMeta: DashboardWidgetMetaResult[]
}

export type DashboardWidgetMetaResult = DashboardWidgetMeta | DashboardGroupedWidgetMeta;