import * as React from 'react';
import { Avatar, Dropdown, Tooltip } from 'antd';
import {  observer } from 'mobx-react-lite';
import WrappedUserProfileEditForm from '../components/UserProfileEdit';
import ResetPasswordDialog from './ResetPasswordDialog';
import { useStore } from '../../common/stores';

const UserProfile: React.FC = () => {
    const store = useStore().userProfileStore;
    const [imageUrl, setimageUrl] = React.useState('');
    React.useEffect(() => {
        let hasCancelled = false;  
        const avatarIconFileId = store!.userProfile && store!.userProfile.avatarIconFileId;
        if (avatarIconFileId) {
            store!.getAvatarIcon(avatarIconFileId).then(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (val: any) => hasCancelled ? () => {
                    return; 
                } : setimageUrl(URL.createObjectURL(val) || ''),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error: any) => hasCancelled ? () => {
                    return; 
                } : () => console.log(error)
            );      
        }

        return () => {
            hasCancelled = true;
        };
    },              [store.userProfile, store]);

    const isDomainUser = store.userProfile && store.userProfile.isDomainUser;

    const handleLogout = () => {
        store.logout();
    };

    const menu = (
        {
            items: [
                {
                    key: 1,
                    label: (
                        <div 
                            onClick={() => store.setEditUserProfileVisible(true)}
                        >
                            My Profile
                        </div>
                    )
                },
                {
                    key: 2,
                    label: (
                        <div 
                            onClick={() => store.setResetPasswordDialogVisible(true)}
                            style={isDomainUser ? {display: 'none'} : {} }
                        >
                            Change password
                        </div>
                    )
                },
                {
                    key: 3,
                    label: (
                        <div 
                            onClick={handleLogout}
                        >
                            Logout
                        </div>
                    )
                },
            ]}
    );

    const getUserInitial = () => {
        if (!store.userProfile) {
            return null;
        }

        return store.userProfile.firstName &&
        store.userProfile.firstName.charAt(0).toUpperCase() || 
        store.userProfile.userName && store.userProfile.userName.charAt(0).toUpperCase();
    };

    return (
        <>
            <WrappedUserProfileEditForm store={store}/>
            <ResetPasswordDialog store={store}/>
            {store.userProfile ?
                <div className="avatar-icon">
                    <Dropdown menu={menu} trigger={['click']}>
                        <Tooltip title={store.userProfile.firstName || store.userProfile.userName}>
                            {imageUrl ? <Avatar size={32} src={imageUrl}/> : <Avatar size={32}>{getUserInitial()}</Avatar>}
                        </Tooltip>
                    </Dropdown>
                </div> : 
                <div className="avatar-icon">
                    <Dropdown menu={menu} trigger={['click']}>
                        {imageUrl ? <Avatar size={32} src={imageUrl}/> : <Avatar size={32}>{getUserInitial()}</Avatar>}
                    </Dropdown>
                </div> }
        </>);
};

export default observer(UserProfile);