import { Tabs, Button, List, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ReactQuill from 'react-quill';
import { TaskComment, TaskActivityEntry } from '../../../tasks/components';
import { TaskCommentsStore } from '../../../tasks/stores';
import TaskViewVisualStore from '../../../tasks/stores/TaskViewVisualStore';
import { Utils } from '../../misc/Utils';
import { CommentOutlined, HistoryOutlined, LoadingOutlined, ThunderboltOutlined } from '@ant-design/icons';
import ActionEventView from '../../../tasks/components/ActionEventView';
import { useTasksStore } from '../../../tasks/stores/context';

type TaskCommentsSectionProps = {
    store: TaskViewVisualStore;
    commentsStore: TaskCommentsStore;
    isFullView?: boolean
};

const TaskCommentsSection: React.FC<TaskCommentsSectionProps> = ({ store, commentsStore, isFullView = false }) => {
    const tasksRootStore = useTasksStore().tasksRootStore;

    const tabItems = [
        {
            key: '1',
            label: <span data-id="button-comments-list-expnaded">Comments</span>,
            icon: commentsStore.commentsLoading ? <LoadingOutlined /> : <CommentOutlined />,
            children: commentsStore.commentsLoading ?
                <Skeleton avatar /> :
                commentsStore.comments && commentsStore.comments.length > 0 ? (
                    <div className="comments-container">
                        {commentsStore.comments.map((c, i) => (
                            <div key={i} data-id-type="flag-comments-expanded">
                                <TaskComment
                                    store={commentsStore}
                                    currentUserId={store.currentUserId}
                                    comment={c}
                                    disabled={store.taskFormLocked}
                                    taskPrefix={store.taskPrefix}
                                    taskStore={store}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="empty-message">There are no comments yet.</div>
                ),
        },
        {
            key: '2',
            label: <span data-id="button-activity-list-expnaded">Activity</span>,
            icon: store.taskActivityLoading ? <LoadingOutlined /> : <HistoryOutlined />,
            children: store.taskActivityLoading ?
                <Skeleton avatar /> :
                store.taskActivities && store.taskActivities.length > 0 ? (
                    <div className="task-activity-container" style={{ maxHeight: 'calc(100vh - 160px)', overflow: 'auto' }}>
                        {store.taskActivities.map((a, i) => (
                            <div key={i} data-id-type="flag-activity-expanded">
                                <TaskActivityEntry
                                    store={store}
                                    activity={a}
                                    userName={store.allUsersFullNameResolver[a.doneBy] || a.doneBy}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="empty-message">There are no activity records yet.</div>
                ),
        },
        {
            key: '3',
            label: <span data-id="button-activity-list-expnaded">Actions</span>,
            icon: store.actionEventsLoading ? <LoadingOutlined /> : <ThunderboltOutlined />,
            children: store.actionEventsLoading ?
                <Skeleton avatar /> :
                store.actionEvents && store.actionEvents.length > 0 ? (
                    <List
                        size="small"
                        className="at-task-actions"
                        dataSource={store.actionEvents}
                        renderItem={(item) => (
                            <ActionEventView store={store} rootStore={tasksRootStore} event={item} />
                        )}
                    />
                ) : (
                    <div className="empty-message">There are no action events yet.</div>
                ),
        },
    ];

    return (
        <>
            <Tabs className="full-width-tabs activity-tabs-wrapper" animated={false} items={tabItems}/>
            {isFullView && <div className="comment-input-container">
                <div className="comment-input-wrapper" data-id={'input-comment'}>
                    <ReactQuill
                        modules={Utils.getQuilModules()}
                        readOnly={store.taskFormLocked}
                        value={commentsStore.taskNewComment || ''}
                        onChange={commentsStore.setTaskNewComment}
                        // eslint-disable-next-line max-len
                        className={`comment-input alpha-quill tb-bottom ${commentsStore.taskNewComment && commentsStore.taskNewComment.replace(Utils.htmlTags, '').trim().length > 0 ? 'has-text' : ''}`}
                        placeholder="Add comment..."
                    />
                    <div className="comment-controls">
                        <Button
                            type="primary"
                            disabled={!commentsStore.canPostComment}
                            onClick={() => commentsStore.postComment(store.taskPreview!.id)}
                            loading={commentsStore.postingComment}
                        >
                            Comment
                        </Button>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default observer(TaskCommentsSection);