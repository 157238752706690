import { action, makeObservable, observable, runInAction } from 'mobx';
import { ErrorStore } from '../../common/stores';
import { GlobalAdministrationService } from '../services/GlobalAdministrationService';
import { MetadataDefinition, MetadataFieldType } from '../types/Metadata';
import { message } from 'antd';
import { TaskType } from '../../task_types/types';
import TaskTypesService from '../../task_types/services/TaskTypesService';

export default class MetadataDefinitionStore {
    metadataDefinitions: MetadataDefinition[] = [];
    editDefinitionDialogVisible: boolean = false;
    editDialogMode: 'create' | 'edit' = 'create';
    selectedDefinition: MetadataDefinition | null;
    selectedProjectId: string;
    isTableLoding: boolean;
    taskTypes: TaskType[] = [];

    constructor(private adminService: GlobalAdministrationService, private errorStore: ErrorStore, private taskTypesService: TaskTypesService) {
        makeObservable<MetadataDefinitionStore>
        (this, {
            metadataDefinitions: observable,
            editDefinitionDialogVisible: observable,
            editDialogMode: observable,
            selectedDefinition: observable,
            selectedProjectId: observable,
            isTableLoding: observable,
            taskTypes: observable,
            setNewDefinitionDialogVisible: action.bound,
            setSelectedProjectId: action.bound,
            loadDefinitions: action.bound,
            selectMetadataDefinition: action.bound,
            createMetadataDefinition: action.bound,
            deleteMetadataDefinition: action.bound,
            updateMetadataDefinition: action.bound,
            handleTypeChange: action.bound
        });
    }

    selectMetadataDefinition(definition: MetadataDefinition | null) {
        this.selectedDefinition = definition;
        if (!definition) {
            this.editDefinitionDialogVisible = false;
        } else {
            this.editDialogMode = 'edit';
            this.editDefinitionDialogVisible = true;
        }
    }

    setNewDefinitionDialogVisible(visible: boolean) {
        this.selectedDefinition = { id: '', name: '', fieldType: 'Text', projectId: this.selectedProjectId, 
            title: '', description: '', updateDate: null, position: this.metadataDefinitions.length, isVisible: true, isEditable: false, taskTypes: [] };
        this.editDialogMode = 'create';
        this.editDefinitionDialogVisible = visible;
    }

    setSelectedProjectId(projectId: string) {
        this.selectedProjectId = projectId;
        this.loadDefinitions(projectId);
        this.loadTaskTypes(projectId);
    }

    async loadDefinitions(projectId: string) {
        if (!projectId) {
            return;
        }
        const resp = await this.adminService.getMetadataDefinitionsForProject(projectId);
        resp.map((metadata) => {
            runInAction(() => {
                this.metadataDefinitions = metadata;
            });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    async createMetadataDefinition(formValues: FormData) {
        try {
            const resp = await this.adminService.createMetadataDefinition(formValues);
            resp.map(() => {
                runInAction(() => {
                    this.loadDefinitions(this.selectedProjectId);
                    this.setNewDefinitionDialogVisible(false);
                });
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                this.errorStore.addError(err.data);
            });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    async deleteMetadataDefinition(metadataDefinitionId: string) {
        var response = await this.adminService.deleteMetadataDefinition(metadataDefinitionId);
        response.map(() => {
            runInAction(() => {
                let newDefinitions = this.metadataDefinitions.slice();
                newDefinitions = newDefinitions.filter(t => t.id !== metadataDefinitionId);
                this.metadataDefinitions = newDefinitions;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => {
            if (err.status === 409) {
                message.error(err.data!.title);
            } else {
                this.errorStore.addError(err.data);
            }
        });
    }

    async updateListOrder(id: string, dragIndex: number, dropIndex: number) {
        this.isTableLoding = true;
        const metadata = this.metadataDefinitions.find(m=> m.id === id)!;
        const resp = await this.adminService.updateMetadataListOrder(id, dragIndex, dropIndex, metadata.updateDate);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resp.mapErr((err: any) => {
            this.errorStore.addError(err.data);
        });
        await this.loadDefinitions(this.selectedProjectId);
        runInAction(() =>  this.isTableLoding = false);
    }

    async updateMetadataDefinition(title: string, description: string | undefined, format: string, isVisible: boolean, isEditable: boolean, taskTypes: string[]) {
        let metadataDefinitionId = this.selectedDefinition!.id;
        var response = await this.adminService.updateMetadataDefinition(metadataDefinitionId, title, description, format, isVisible, isEditable, taskTypes);
        response.map(() => {
            runInAction(() => {
                let newDefinitions = this.metadataDefinitions.slice();
                const definitionToUpdate = newDefinitions.find(t => t.id === metadataDefinitionId);

                if (definitionToUpdate) {
                    const index = newDefinitions.indexOf(definitionToUpdate);
                    newDefinitions[index].title = title;
                    newDefinitions[index].description = description;
                    newDefinitions[index].format = format;
                    newDefinitions[index].isVisible = isVisible;
                    newDefinitions[index].isEditable = isEditable;
                    newDefinitions[index].taskTypes = taskTypes;
                }

                this.metadataDefinitions = newDefinitions;
                this.selectMetadataDefinition(null);
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }).mapErr((err: any) => this.errorStore.addError(err.data));
    }

    handleTypeChange(val: MetadataFieldType) {
        this.selectedDefinition!.fieldType = val;
    }

    private async loadTaskTypes(projectId: string) {
        const resp = await this.taskTypesService.getTaskTypesForProject(projectId);
        resp.map(t=> {
            runInAction(() => {
                this.taskTypes = t;
            });
        }).mapErr((err) => this.errorStore.addError(err.data));
    }
}