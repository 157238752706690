import * as React from 'react';
import { TaskViewVisualStore } from '../stores';
import { observer } from 'mobx-react-lite';
import { DatePicker, Form, Input, Select, TimePicker, Upload, message } from 'antd';
import { ActionField } from '../../administration/types/Actions';
import TextArea from 'antd/lib/input/TextArea';
import { UploadChangeParam } from 'antd/lib/upload';
import { FULL_DATE, ONLY_TIME, Utils } from '../../common/misc/Utils';

type Props = {
    taskId: string;
    actionId: string;
    store: TaskViewVisualStore
};

const Dragger = Upload.Dragger;

const ActionInputForm: React.FC<Props> = ({ taskId, actionId, store }) => {
    const [form] = Form.useForm();
    React.useEffect(() => {
        if (!store.selectedAction) {
            form.resetFields();
        }
    }, [store.selectedAction, form]);

    const action = store.availableActions.find(x => x.id === actionId)!;
    
    const fields = action.fields.filter(x => x.fieldType === 'UserInput') || [];

    const onChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            store.setIsActionLoading(true);
            let payload = {};
            Object.keys(values).forEach((k) => {
                let field = fields.find(x => x.name === k);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                payload[k] = field?.editor?.$type === 'FileEditorConfig' ? values[k]?.fileList?.flatMap((x: any) => x?.response) || [] : values[k];
            });

            await store.executeAction(taskId, actionId, payload, action.isClientSide)
                .then(() => { 
                    store.setSelectedAction(undefined, undefined);
                })
                .catch(err => {
                    message.error('An error occured during execution of action.');
                    console.log(err);
                })
                .finally(() => {
                    store.setIsActionLoading(false);
                });
        }).catch((err) => {
            console.log(err);
        });
    };

    const getProjectId = () => {
        return store.tasks.find(t=> t.id === taskId)!.projectId;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getInput = (config?: any,) => {
        let editorType = config?.$type;
        switch (editorType) {
        case 'SelectEditorConfig': {
            let props = {
                options: config.items.map((x: string) => ({ value: x, label: x }))
            };

            if (config.allowMultiple) {
                Object.assign(props, { mode: 'multiple' });
            }

            return <Select {...props} />;
        }
        case 'TextEditorConfig': {
            let props = config.maxLength ? { maxLength: config.maxLength } : {};

            if (config.isMultiLine) {
                return <TextArea {...props} />;
            } else {
                return <Input {...props} />;
            }
        }
        case 'FileEditorConfig': {
            let props = {
                multiple: config.allowMultiple || false,
                maxCount: config.allowMultiple ? 5 : 1
            };

            if (config.extension) {
                Object.assign(props, { accept: config.extension });
            }

            return <Dragger
                className="upload-dragger"
                name="files"
                onChange={onChange}
                action={`${process.env.REACT_APP_TASKS_URL}file-upload/multiple/project/${getProjectId()}`}
                {...props}
            >
                <p className="ant-upload-hint">
                    <i className="alpha-icon md upload" style={{ verticalAlign: 'middle' }} />
                    Drag and drop file here to upload...
                </p>
            </Dragger>;
        }
        case 'DateEditorConfig': {
            return config.dateType === ONLY_TIME ? 
                <TimePicker style={{width: '100%'}}/> : 
                <DatePicker format={Utils.getDateFormat(config.dateType === FULL_DATE)} style={{width: '100%'}} showTime={config.dateType === FULL_DATE}/>;
        }
        default: {
            return <Input />;
        }
        }
    };

    const getItem = (field: ActionField) => {
        return (
            <Form.Item
                className="dialog-field"
                colon={false}
                label={<span className="dialog-field-label">{field.title ?? field.name}</span>}
                name={field.name}
                key={field.name}
                rules={field.isRequired ? [{ required: true, message: `${field.title ?? field.name} field is required` }] : []}
                initialValue={field.defaultValue}
            >
                {getInput(field.editor)}
            </Form.Item>
        );
    };
    return (
        <Form form={form} onFinish={handleSubmit} className="alpha-form" layout='vertical' id="execute-action-form">
            {fields.map(x => getItem(x))}
        </Form>
    );
};

export default observer(ActionInputForm);