
import React from 'react';
import { Checkbox, Form, FormInstance, Input, Select, Tooltip } from 'antd';
import ReportsStore from '../stores/ReportsStore';
import { observer } from 'mobx-react-lite';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
    store: ReportsStore;
    form: FormInstance
};

const ReportEditForm: React.FC<Props> = ({ store, form }) => {
    React.useEffect(() => {
        if (store.selectedReport) {
            form.setFieldsValue(store.selectedReport);
        }
    }, [store.editReportDialogVisible, store.selectedReport, form]);

    const handleSubmit = () => {
        form.validateFields().then((formData) => {
            if (store.editDialogMode === 'create') {
                store.publishReport(formData);
            } else {
                store.updateReport(formData);
            }
            form.resetFields();
        }).catch((err) => {
            console.log(err);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const titleValidator = (rule: any, value: any, callback: any) => { 
        if(!value) {
            callback();
        }
        const existingTitles = store!.reports.map(r => r.title.toLowerCase());
        if (existingTitles.includes(value.toLowerCase()) && store.selectedReport?.title !== value) {
            callback('Report with the same title already exists');
        } else {
            callback();
        }
    };

    return (
        <div className="dialog-section">
            <Form form={form} onFinish={handleSubmit} className="inputs-container" id="edit-published-report-form" layout="vertical">
                <Form.Item
                    name="title"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[
                        { required: true, message: 'Title is required' },
                        { validator: titleValidator }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="source"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Report source</span>}
                    rules={[{ required: true, message: 'Report source is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Description</span>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="reportAccess"
                    colon={false}
                    className='dialog-field'
                    label={<span className="dialog-field-label">Report access</span>}
                    rules={[{ required: true, message: 'Report Access is required' }]}
                >
                    <Select
                        suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                    >
                        {Object.keys(store.reportAccessOptions).map(x => <Select.Option value={x} key={x}>{store.reportAccessOptions[x]}</Select.Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="projects"
                    colon={false}
                    className='dialog-field'
                    label={
                        <div>
                            <span className="dialog-field-label">Projects</span>
                            <Tooltip title='If no project is selected, the report will be available for all projects'>
                                <InfoCircleOutlined style={{marginLeft: 10}}/>
                            </Tooltip>
                        </div>
                    }
                >
                    <Select
                        mode='multiple'
                        options={store.projects.map(p => ({ value: p.id, label: p.name }))}
                    />
                </Form.Item>
                <Form.Item
                    name="enabled"
                    valuePropName="checked"
                >
                    <Checkbox>
                        Is enabled
                    </Checkbox>
                </Form.Item>
            </Form>
        </div>
    );
};

export default observer(ReportEditForm);