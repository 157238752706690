import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, message } from 'antd';
import UsersManagerStore from '../stores/UsersManagerStore';
import { ProjectsAssignmentTable } from './ProjectsAssignmentTable';

type Props = {
    store: UsersManagerStore
};

export const EditProjectAssignmensDialog: React.FC<Props> = observer(({ store }) => {
    const handleCancel = () => {
        store.clearEditableProjects();
        store.setIsProjectRolesDialogVisible(false);
    };

    const handleSubmit = () => {
        if (store.editableProjectsForUser.length === 0) {
            message.error('Please select at least one project');
            return;
        }
        store.updateUserAssignments()
            .then(() => store.clearEditableProjects());
    };
    
    return (
        <Modal
            open={store!.isEditProjectsRolesDialogVisible}
            onCancel={() => store.setIsProjectRolesDialogVisible(false)}
            maskClosable={false}
            closable={false}
            title='Project assignments'
            destroyOnClose
            width={660}
            centered
            className="alpha-modal"
            footer={[
                <Button className="light" data-id="add-user-dialog-cancel" size="large" key="cancel" onClick={handleCancel}>
                   Cancel
                </Button>,
                <Button data-id="add-user-dialog-submit" size="large" key="submit" type="primary" loading={store.tableIsLoading} onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            <ProjectsAssignmentTable store={store}/>
        </Modal>);
});
export default EditProjectAssignmensDialog;