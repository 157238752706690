import { Dropdown, Button, Skeleton, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { TaskViewVisualStore } from '../stores';
import { useStore } from '../../common/stores';

type Props = {
    store: TaskViewVisualStore;
    taskId: string
};
const TaskProgressControl: React.FC<Props> = ({ taskId, store }) => {
    const {errorStore} = useStore();
    const statuses = [
        {status: 'Received', color: '#52C41A'}, 
        {status: 'Open', color:'#273C75'}, 
        {status: 'In Progress', color:'#273C75'}, 
        {status: 'Closed', color: '#9BA0AA'},
        {status: 'Failed', color: '#FF0000'},
        {status: 'Queried', color: '#FFB950'},
        {status: 'Rejected', color: '#E57C23'}
    ];
    
    const handleStatusClick = async(statusId: string) => {
        const status = store.taskStatusesForType.find(t=> t.id === statusId)!;
        const callback = status.isFinal ? async() => {
            const resp = await store.getTaskById(taskId);
            resp.map(task => {
                if (task) {
                    // TODO: May be it is time to make TaskPrevew a dedicated class
                    store.taskPreview!.subTasksMeta = [];
                    store.taskPreview!.subTasksMeta = task.subTasksMeta;
                }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }).mapErr((err: any) => {
                errorStore.addError(err.data);
            });
        } : undefined;
        await store.updateField(taskId, 'Status', statusId, callback, undefined, status.isFinal);
        store.loadAvailableActions();
        if (store.taskAttachments.length > 0) {
            store.loadTaskAttachments();
        }
    };

    const getColor = (status: string | undefined) => {
        return statuses.find(s=> s.status === status)?.color || '#9BA0AA';
    };

    const getSortedStatuses = () => {
        const sorteredStatuses =  ['Received', 'Open', 'In Progress', 'Queried', 'Processed', 'Failed', 'Rejected'];
        return store.taskStatusesForType.slice().sort((a,b) => sorteredStatuses.indexOf(a.name) - sorteredStatuses.indexOf(b.name));
    };

    const columnsMenu = (): MenuProps => {
        const items = store.taskStatusesForType && getSortedStatuses().map((s, i)=> (
            {
                key: i,
                label: (
                    <>
                        <div style={{background: getColor(s.name)}}/>
                        <span>{s.name}</span>
                    </>),
                disabled: !store.taskType?.canChangeStatusManually,
                style: {display: store.getTaskStatusDisplay(s), padding: 15},
                onClick: () => handleStatusClick(s.id)
            }));
        return {items};
    };

    return(
        <>
            {store.taskStatusName ?
                <div className='task-status-container'>
                    <Dropdown
                        trigger={['click']}
                        menu={columnsMenu()}
                        overlayClassName="alpha-dropdown-overlay task-status-menu"
                    >
                        <div style={{display: 'inline-block'}}>
                            <Button 
                                disabled={store.taskStatusIdsFiltered.length === 0} 
                                className="task-status" 
                                style={{background: getColor(store.taskStatusName)}}
                            >
                                {store.taskStatusName}
                            </Button>
                        </div>
                    </Dropdown>
                </div>: 
                <div style={{display: 'inline-block', height: 22, marginTop: 4, width: 100}}>
                    <Skeleton title={false} avatar={false} paragraph={{ rows: 1, width: '90%'}} active/>
                </div>
            }
        </>
    );
};

export default observer(TaskProgressControl);