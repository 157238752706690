import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { TaskViewVisualStore } from '../stores';
import { Button, Col, Dropdown, MenuProps, Skeleton } from 'antd';
import ActionInputDialog from './ActionInputDialog';

type Props = {
    store: TaskViewVisualStore
};

const TaskActionControl: React.FC<Props> = ({ store }) => {
    const handleExecuteActionClick = (actionId: string) => {
        const action = store.filteredAvailableTaskActions
            .find(x => x.action.id === actionId)?.action;
        const hasUserInputFields = action!.fields
            .some(x => x.fieldType === 'UserInput') || false;

        if (hasUserInputFields) {
            // Display form
            store.setSelectedAction(actionId, undefined);
        } else {
            // Execute action
            store.executeAction(store.taskPreview!.id, actionId, {}, action!.isClientSide, undefined)
                .catch(err => console.log(err));
        }
    };

    const actionsMenu: MenuProps = {
        items:  store.filteredAvailableTaskActions.slice(2, store.filteredAvailableTaskActions.length).map(x =>
            ({

                key: x.action.id,
                label: (
                    <div 
                        style={{padding: 15}} 
                        onClick={() => handleExecuteActionClick(x.action.id)}
                    >
                        {x.action.title ?? x.action.name}
                    </div>
                ),
                disabled: !x.canExecute
            }))     
    };

    return (
        <>
            {store.selectedAction &&
                <ActionInputDialog
                    taskId={store.taskPreview!.id}
                    actionId={store.selectedAction}
                    store={store}
                />
            }
            {store.availableActionsLoading &&
                <Skeleton.Button active size="default" shape="square" />
            }
            {
                store.filteredAvailableTaskActions.slice(0, 2).map(a=> 
                    (
                        <Col key={a.action.id}>
                            <Button onClick={() => handleExecuteActionClick(a.action.id)} disabled={!a.canExecute}>{a.action.title ?? a.action.name}</Button>
                        </Col>
                    )
                )
            }
            {store.filteredAvailableTaskActions.length > 2 && 
                <Col>
                    <Dropdown menu={actionsMenu} trigger={['click']} overlayClassName="alpha-dropdown-overlay action-menu">
                        <Button>...</Button>
                    </Dropdown>
                </Col>
            }
        </>
    );
};

export default observer(TaskActionControl);