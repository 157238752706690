import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { TasksGridVisualStore } from '../stores';
import { Button, DatePicker, Form, Modal, Select } from 'antd';
import { Utils } from 'src/modules/common/misc/Utils';
import { AccessType } from '../stores/TaskViewVisualStore';
import { AccessDropdownItems } from './AccessDropdownItems';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    store: TasksGridVisualStore
};

const FormItem = Form.Item;

const BulkTasksUpdateDialog: React.FC<Props> = ({ store }) => {
    const [isSharedWithVisible, setIsSharedWithVisible] = React.useState(false);
    const [form] = Form.useForm();

    React.useEffect(() => {
        const {selectedProject} = store;
        if (store.selectedProject) {
            store.loadUsersForProject(selectedProject);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disabledDate = (current: Dayjs) => {
        return current && current < dayjs().endOf('day');
    };


    const handleSubmit = () => {
        form.validateFields().then((vals) => {
            const fields = {} as {[key: string]: unknown};
            Object.keys(vals).forEach(key=> {
                if (vals[key]) {
                    fields[key] = vals[key];
                }
            });
            if (fields.SharedWith) {
                fields.SharedWith = [...fields.SharedWith as string[], store.currentUserId];
            }
            if (fields.OnlyMe || fields.Everyone) {
                fields.SharedWith = [];
            }
            store.updateFieldsBulk(fields);
        });
        store.setIsBulkUpdateDialogOpen(false);
    };

    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAccessChange = (value: string, option: any) => {
        if (isSharedWithVisible) {
            setIsSharedWithVisible(false);
            form.setFieldsValue({ 'sharedWith': [] });
        }
        if (option.label === AccessType.OnlyMe) {
            form.setFieldsValue({ assignedTo: '' });
        } else {
            if (option.label === AccessType.LimitedAccess) {
                setIsSharedWithVisible(true);
            } 
        }
    };

    return (
        <Modal
            title={'Update fields'}
            open={store.isBulkUpdateDialogOpen}
            className="alpha-modal comment-in-modal"
            closable={false}
            footer={[
                <Button
                    data-id="execute-action-cancel-button"
                    key="execute-action-cancel-button"
                    className="light"
                    onClick={() => store.setIsBulkUpdateDialogOpen(false)}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="execute-action-button"
                    key="submit-button"
                    type="primary"
                    htmlType="submit"
                    form='bulk-update-form'
                >
                    Save
                </Button>
            ]}
        >
            <Form form={form} onFinish={handleSubmit} layout='vertical' id="bulk-update-form" >
                <FormItem
                    name="AssignedTo"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Assignee</span>}
                >
                    <Select
                        suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                        options={store.usersInProject.filter(u=>!u.isDeleted).map(p => ({ label: store.allUsersFullNameResolver[p.userId], value: p.userId }))}
                        filterOption={Utils.filterOption}
                        showSearch
                        style={{width: '100%'}}
                        loading={store.loadingUsers}
                        placeholder='Select assignee'
                    /> 
                </FormItem>
                <FormItem name="DueDate" colon={false} className="dialog-field" label={<span className="dialog-field-label">Due date</span>}>
                    <DatePicker
                        suffixIcon={<i className="alpha-icon xs calendar-icon" style={{ margin: 0 }} />}
                        format={Utils.getDateFormat()}
                        style={{width: '100%'}}
                        disabledDate={disabledDate}
                    />
                </FormItem>
                <FormItem name="Reminder" colon={false} className="dialog-field" label={<span className="dialog-field-label">Reminder</span>}>
                    <DatePicker
                        suffixIcon={<i className="alpha-icon xs calendar-icon" style={{ margin: 0 }} />}
                        format={Utils.getDateFormat()}
                        style={{width: '100%'}}
                        disabledDate={disabledDate}
                    />
                </FormItem>
                <FormItem 
                    name="Priority" 
                    colon={false} 
                    className="dialog-field" label={<span className="dialog-field-label">Priority</span>}
                >
                    <Select 
                        suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />} 
                        style={{width: '100%'}}
                        placeholder='Select priority'
                        options={Utils.getTaskPrioritiesForDropdown()}/>
                </FormItem>
                {
                    store.selectedTasksShareSameStatus ? 
                        <FormItem 
                            name="Status" 
                            colon={false} 
                            className="dialog-field" label={<span className="dialog-field-label">Status</span>}
                        >
                            <Select 
                                // TODO: Move suffixIcon styles to less file
                                suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />} 
                                options={store.taskStatusesForBulkUpdate.map(s=> ({label: s.name, value: s.id}))}
                                style={{width: '100%'}}
                                placeholder='Select status'
                            />
                        </FormItem> : null
                }
                {
                    store.selectedTasksShareSameOwner ? 
                        <FormItem 
                            name="AccessType" 
                            colon={false} 
                            className="dialog-field" label={<span className="dialog-field-label">Access</span>}
                        >
                            <Select 
                                // TODO: Move suffixIcon styles to less file
                                suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />} 
                                style={{width: '100%'}}
                                placeholder='Select access type'
                                optionLabelProp="label"
                                dropdownStyle={{ minWidth: '355px' }}
                                onChange={handleAccessChange}
                            >
                                {Object.keys(AccessType).map(p => (
                                    <Select.Option key={p} value={p} label={AccessType[p]} >
                                        {AccessDropdownItems[p]}
                                    </Select.Option>
                                ))}
                            </Select>
                        </FormItem> : null
                }
                {isSharedWithVisible ?
                    <Observer>
                        {() => (
                            <FormItem
                                name="SharedWith"
                                colon={false}
                                className="dialog-field"
                                label={<span className="dialog-field-label">Share with</span>}
                                rules={[{ required: true, message: 'Share with is required' }]}
                            >
                                <Select
                                    mode="multiple"
                                    optionLabelProp="label"
                                    suffixIcon={<i className="alpha-icon xxs arrow-down-icon" style={{ margin: 0, width: 8 }} />}
                                    options={store.usersInProject
                                        .filter(u=>!u.isDeleted && u.userId !== store.currentUserId)
                                        .map(p => ({ label: store.allUsersFullNameResolver[p.userId], value: p.userId }))}
                                    style={{width: '100%'}}
                                />
                            </FormItem>
                        )}
                    </Observer>
                    : null}
            </Form>
        </Modal>
    );
};

export default observer(BulkTasksUpdateDialog);