import * as React from 'react';
import { ErrorDetails, TaskError } from '../types';
import { Button, Modal, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Utils } from 'src/modules/common/misc/Utils';
import { useState } from 'react';

type Props = {
    errors: TaskError[]
};
export const ErrorRenderer: React.FC<Props> =  ({errors}) => {
    const [currentDetails, setCurrentDetails] = useState<ErrorDetails | null>(null);

    if (!errors.length) {
        return null;
    }

    const columns: ColumnProps<TaskError>[]  =  [
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Date created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => {
                return a.createdAt.localeCompare(b.createdAt); 
            }, 
            sortDirections: ['ascend', 'descend'], 
            defaultSortOrder: 'descend',
            render: (date: string) =>{
                const formattedDate = Utils.formatDateStringShort(date, true, true);
                return {
                    children: (
                        <span>{formattedDate}</span>
                    )
                };
            } 
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            render: (details: ErrorDetails) => {
                return {
                    children: (
                        <Button onClick={() => setCurrentDetails(details)} type='primary'>
                            View details
                        </Button>
                    )
                };
            }
        }
    ];

    return (
        <>
            {currentDetails && 
                <Modal open footer={false} onCancel={() => setCurrentDetails(null)}>
                    {Object.keys(currentDetails).map((key)=> (
                        <>
                            {currentDetails[key][0].description ?
                                <>
                                    <div><h3>{currentDetails[key][0].description}</h3></div>
                                    {currentDetails[key][1].fields?.map((f: unknown, i: number)=> <div key={i}>{f}</div>)}
                                </> :
                                Array.isArray(currentDetails[key]) ? currentDetails[key].map((f: unknown, i: number)=> <div key={i}>{f}</div>)
                                    : <div>{currentDetails[key]}</div>
                            }
                        </>))}
                </Modal>}
            <Table className='alpha-table' size='small' dataSource={errors} columns={columns} pagination={false}/>
        </>
    );

};