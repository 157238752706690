import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { MetadataDefinition } from '../../administration/types/Metadata';
import { TaskModel } from '../types';
import { DatePicker, Input, InputNumber, InputRef, Select } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TaskViewVisualStore } from '../stores';
import { AuthConsumer } from '../../authorization/AuthContext';
import { AppPermissions } from '../../authorization/Permissions';
import { hasPermission } from '../../authorization/components/HasPermission';
import { ONLY_DATE, ONLY_TIME, Utils } from '../../common/misc/Utils';
import { Dayjs } from 'dayjs';

type Props = {
    store: TaskViewVisualStore;
    definition: MetadataDefinition;
    task: TaskModel
};

const MetadataEditor: React.FC<Props> = ({ store, definition, task }) => {
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const inputRef = React.useRef<InputRef>(null);

    React.useEffect(() => {
        if (inputRef.current && isEditing) {
            inputRef.current.focus();
        }
    }, [inputRef, isEditing]);

    const handleTextInputUpdate = () => {
        store.updateMetadataField(task.id, definition.name, inputRef.current?.input?.value)
            .then(() => setIsEditing(false));
    };

    const handleInputUpdate = (val: unknown) => {
        store.updateMetadataField(task.id, definition.name, val || null)
            .then(() => setIsEditing(false));
    };

    const handleDateUpdate = (date: Dayjs) => {
        const ignoredTimezoneDate = Utils.removeLocalTimeZone(date);
        store.updateMetadataField(task.id, definition.name, ignoredTimezoneDate)
            .then(() => setIsEditing(false));
    }; 

    const getMetadataParseValue = () => {
        // TODO: Created shared handler as this logics is duplicated in TasksList component
        const nativeVal = task.metadata![definition.name];
        const format = definition.format;
        switch(definition.fieldType) {
        case 'Text':
            if (format && nativeVal) {
                const {prefix, suffix} = JSON.parse(format);
                return `${prefix ?? ''}${nativeVal}${suffix ?? ''}`;
            } 
            
            return nativeVal;

        case 'Boolean':
            if (format && nativeVal !== undefined) {
                const {trueVal, falseVal} = JSON.parse(format);
                if (nativeVal && trueVal) {
                    return trueVal;
                }
                if (nativeVal === false && falseVal) {
                    return falseVal;
                }
            }
            return nativeVal?.toString() ?? '';
        case 'Number':
            if (format && nativeVal) {
                const {precision, separator} = JSON.parse(format);
                if (separator) {
                    const fractionDigits = precision ?? nativeVal.toString().split('.')[1]?.length ?? 0;
                    return (nativeVal as Number).toLocaleString(undefined, {maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits});
                } else if(precision) {
                    return (nativeVal as Number).toFixed(precision);
                } else {
                    return nativeVal;
                }
            }
            return nativeVal;
        case 'DateTime':
            switch (format) {
            case ONLY_DATE:
                return Utils.formatDateStringShort(nativeVal, true, true, false, true);
            case ONLY_TIME:
                return Utils.formatDateStringShort(nativeVal, false, false, true, true);
            default:
                return Utils.formatDateStringShort(nativeVal, false, false, false, true);
            }
        default: 
            return nativeVal;
        }
    };

    const widget = () => {
        switch (definition.fieldType) {
        case 'Number': {
            const {precision, separator} = definition.format ? JSON.parse(definition.format) : {precision:  2, separator: false};
            const stringifiedValue = task.metadata![definition.name]?.toString();
            const decimalCount =  stringifiedValue?.includes('.') ? stringifiedValue.split('.')[1].length ?? 0 : 0;
            const step = `0.${'0'.repeat((decimalCount || precision || 2) - 1)}1`;
            return (
                <InputNumber 
                    step={step}
                    formatter={ separator ? (value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : undefined}
                    parser={(value) => 
                        value!.replace(/\$\s?|(,*)/g, '')
                    }
                    defaultValue={task.metadata![definition.name]}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onKeyUp={(e: any) => {
                        if (e.key === 'Escape') {
                            setIsEditing(false);
                        }
                        if (e.key === 'Enter') {
                            handleInputUpdate(Number(e.target.value.replace(/(,*)/g, '')));
                        }
                    }}
                />
            );
        }
        case 'Text':
            return (<Input
                ref={inputRef}
                onKeyUp={(e) => {
                    if (e.key === 'Escape') {
                        setIsEditing(false);
                    }
                    if (e.key === 'Enter') {
                        handleTextInputUpdate();
                    }
                }}
                defaultValue={task.metadata ? task.metadata[definition.name] : ''}
                suffix={
                    <>
                        <CheckOutlined onClick={handleTextInputUpdate} />
                        <CloseOutlined onClick={() => setIsEditing(false)} />
                    </>}
            />);
        case 'Boolean': {
            const {trueVal, falseVal} = definition.format ? JSON.parse(definition.format) : {trueVal: 'true', falseVal: 'false'};
            return (
                <Select 
                    allowClear 
                    value={getMetadataParseValue()}
                    onChange={handleInputUpdate} options={[{label: trueVal || 'true', value: trueVal || 'true'}, {label: falseVal || 'false', value: falseVal || 'false'}]}
                />
            );
        }
        case 'DateTime':
            return (
                <DatePicker format={Utils.getDateFormat()} onChange={handleDateUpdate} value={Utils.formatDatePickerValue(task.metadata![definition.name])}/>
            );
        default:
            return <></>;
        }
    };

    return (
        <div className="meta-value">
            <AuthConsumer>
                {({ permissions, projectRoles }) => {
                    return isEditing && definition.format !== ONLY_TIME?
                        widget()
                        :
                        <div
                            className="meta-display"
                            onClick={() => {
                                if (definition.isEditable || !permissions ||
                                    hasPermission(permissions, AppPermissions.CanAccessAdministration, projectRoles, store.taskPreview!.projectId)) {
                                    setIsEditing(true);
                                }
                            }}
                        >
                            {task.metadata ? getMetadataParseValue() : ''}
                        </div>;
                }
                }
            </AuthConsumer>
        </div>
    );
};

export default observer(MetadataEditor);