import { Modal, Button, Form, Input } from 'antd';
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import ActionDefinitionStore from '../stores/ActionDefinitionStore';

type Props = {
    store: ActionDefinitionStore;
    isDialogVisible: boolean;
    closeDialog: () => void
};
const FormItem = Form.Item;

const ImportActionDefDialog: React.FC<Props> = ({ store, isDialogVisible, closeDialog }) => {
    const { Dragger } = Upload;
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            const result = await store.importActionDefinition(values);

            if (result.isOk()) {
                form.resetFields();
                closeDialog();
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleCancel = () => {
        form.resetFields();
        closeDialog();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionsNameValidator = (rule: any, value: any, callback: any) => { 
        if(!value) {
            callback();
        }
        let actionsToValidate = store!.actionDefinitions.map(a => a.name.toLowerCase());
        if (value && actionsToValidate.includes(value.toLowerCase())) {
            callback('Action definition with the same name already exists');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const actionsTitleValidator = (rule: any, value: any, callback: any) => { 
        if(!value) {
            callback();
        }
        let actionsToValidate = store!.actionDefinitions.map(a => a.title?.toLowerCase());
        if (value && actionsToValidate.includes(value.toLowerCase())) {
            callback('Action definition with the same title already exists');
        } else {
            callback();
        }
    };

    return (
        <Modal
            centered
            width={600}
            title="Import definition"
            destroyOnClose
            closable={false}
            maskClosable={false}
            open={isDialogVisible}
            className="alpha-modal"
            footer={[
                <Button
                    data-id="import-project-cancel"
                    key="add-project-cancel"
                    onClick={handleCancel}
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="import-project-submit"
                    key="add-project-submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    form="add-project-form"
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Import definition
                </Button>
            ]}
        >
            <Form
                className="inputs-container"
                style={{ marginTop: 10 }}
                id="new-task-template-form"
                layout="vertical"
                form={form}
            >
                <FormItem
                    name="file"
                    valuePropName="file"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Action definition file</span>}
                    rules={[
                        { required: true, message: 'Action definition file is required' }                    
                    ]}
                    getValueFromEvent={e => {
                        if (!e.file || !e.fileList || !e.fileList.length) {
                            return;
                        }

                        return e.file;
                    }}
                >
                    <Dragger 
                        name="file"
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag a file to this area to upload</p>
                    </Dragger>
                </FormItem>
                <FormItem
                    name="name"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Name</span>}
                    rules={[
                        { required: true, message: 'Action definition name is required' },
                        { validator: actionsNameValidator }
                    ]}
                >
                    <Input data-id="input-Name"/>
                </FormItem>
                <FormItem
                    name="title"
                    colon={false}
                    className="dialog-field"
                    label={<span className="dialog-field-label">Title</span>}
                    rules={[
                        { validator: actionsTitleValidator }
                    ]}
                >
                    <Input data-id="input-Name"/>
                </FormItem>
            </Form>
        </Modal>
    );
};

export default ImportActionDefDialog;